.admission-process{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("../../assests/all-schools-images/45.JPG");
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
    
    .about-txt{
        text-align:flex-start;
        max-width: 800px;
    }
    .about-us h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
    }
    .hero-txt p{
        max-width: 900px ; 
        margin: 10px auto 20px;
        line-height: 1.4;
    }

    /* ...............media query............ */

    @media (max-width: 650px){
        .contact{
            display: block;
        }
        .contact-call{
            padding: 15px;
        } 
        
    }
