/* Prevent horizontal scroll */
body {
   margin: 0;
   padding: 0;
   overflow-x: hidden;
 }
 
 .programs {
   margin: 80px auto;
   width: 90%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   box-sizing: border-box; /* Include padding and border in the element's total width and height */
   padding-left: 40px; /* Replace margin-left */
   padding-right: 40px; /* Replace margin-right */
   flex-wrap: wrap; /* Allow flex items to wrap */
 }
 
 .programs p {
   color: #fff;
   letter-spacing: 1px;
   font-size: 14px;
   font-weight: 300;
 }
 
 .learn-more p {
   color: #fff;
   letter-spacing: 1px;
   font-size: 28px;
   font-weight: 500;
   font-family: 'Roboto', Helvetica, Arial, sans-serif;
 }
 
 .program img {
   width: 100%;
   /* border-radius: 10px; */
   display: inline;
 }
 
 .program {
   flex-basis: 30%;
   position: relative;
   box-sizing: border-box; /* Include padding and border in the element's total width and height */
   margin-bottom: 20px; /* Add margin bottom for spacing */
 }
 
 .program button {
   width: 100%;
   padding: 10px;
   color: #fff;
   background-color: #98344E;
   border: none;
   height: auto;
   font-size: 16px;
   cursor: pointer;
 }
 
 .caption {
   /* border-radius: 10px; */
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("");
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   color: #ffffff;
   cursor: pointer;
   font-size: small;
   opacity: 0;
   padding-top: 60%;
   transition: 0.4s;
   font-family: 'Roboto', Helvetica, Arial, sans-serif;
   font-weight: 300;
 }
 
 .caption p {
   font-size: 20px;
   font-weight: 400;
 }
 
 .caption img {
   width: 40px;
   margin-bottom: 10px;
 }
 
 .program:hover .caption {
   opacity: 1;
   padding-top: 0;
 }
 
 .btn:hover {
   background-color: #98344E; /* Change background color on hover */
 }
 
 /* Make button responsive */
 @media screen and (max-width: 840px) {
   .program button {
     font-size: 16px; /* Adjust font size */
     padding: 10px; /* Adjust padding */
   }
 }
 
 @media screen and (max-width: 840px) {
   .programs {
     flex-direction: column;
     padding-left: 20px; /* Adjust padding for smaller screens */
     padding-right: 20px; /* Adjust padding for smaller screens */
   }
 
   .program {
     flex-basis: 100%; /* Full width on smaller screens */
     margin: 20px 0;
   }
 }
 
 @media screen and (max-width: 550px) {
   .learn-more p {
     font-size: 24px; /* Adjust font size */
   }
 
   .caption p {
     font-size: 18px; /* Adjust font size */
   }
 
   .program button {
     font-size: 14px; /* Adjust font size */
     padding: 10px; /* Adjust padding */
   }
 }
 
 @media screen and (max-width: 400px) {
   .learn-more p {
     font-size: 20px; /* Adjust font size */
   }
 
   .caption p {
     font-size: 16px; /* Adjust font size */
   }
 
   .program button {
     font-size: 14px; /* Adjust font size */
     padding: 10px; /* Adjust padding */
   }
 }
 