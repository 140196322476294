/* Ensure no horizontal scroll */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.choose-boarding img{
  width: 80%;
  height: 100%;

}

.choose-boarding{
  margin: 20px;
  display: flex;
  align-items:normal ;
  justify-content: space-between;
  width: 100%;

}

.boarding{
  width: 100%;
  min-height: 80vh;
  color: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  background: linear-gradient(rgba(8, 0, 32, 0.4), rgba(8, 0, 32, 0.4)), url("../../assests/Dorm/310.jpg");
  background-size: cover;
  background-position: center ;
  background-repeat: no-repeat;
}
  
  .about-boarding{
      text-align:flex-start;
      max-width: 800px;
  }

.about-boarding h1 {
  text-align:flex-start;
  font-size: 30px ;
  font-weight: 600px;
  letter-spacing: 3;
  margin-top: 450px;
}


.open-text-1 h2 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  margin-left: 30px;
  color: #98344e;
  text-align: flex-start;
}

.open-text-3 h2 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  margin-left: 30px;
  color: #98344e;
  text-align: flex-start;
}
.open-text-3 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
  padding-bottom: 15px;
}

.left-part-choose{
  margin-left: 40px;
}

.left-part-choose p{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 300;
    margin: 0;
    padding-left: 30px;
    max-width: 600px;
}

.right-part-choose p{

    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 300;
    margin: 0;
    padding-right: 30px;
    max-width: 600px;
    padding-left: 30px;
}
.right-part-choose {
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
}

.left-part-choose {
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 25px;
  
}


.right-part-choose h2 {
  padding-left: 0;
  margin-left: 0;
  text-align: flex-start;
  color: aqua;
 
}
.left-part-choose h2{
  padding-left: 0;
  margin-left: 0;
  text-align: flex-start;
  /* padding-left: 30px; */
}
.choose-elements h1 {
  font-weight: 350;
  font-size: 2.1rem;
  color: #98344e;
  max-width: 400px;
  padding-top: 15px;
}

.choose-boarding h2{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  color: #98344e;
  text-align: flex-start;
  margin: 0;
  padding-top: 10px;
  

  
}
.choose-boarding p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  text-align: flex-start;
  margin: 0;
  padding-left: 0;
  

  
}

.open-text-1 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
}


.left-part-choose img{
  align-content: center;
  height: 85%;
  width: 100%;
}

.right-part-choose img{
  align-content: center;
  height: 90%;
  width: 100%;
  padding:25px;
  
}

.ex-images {
  margin: 4em;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.ex-images img {
  height: 300px;
  width: 400px;
  padding: 15px;
} 

.open-text-2 h2 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  margin-left: 30px;
  color: #98344e;
  text-align: flex-start;
}

.open-text-2 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
  margin-left: 30px;
  margin-bottom:30px;
}

/* ...............media query............ */

@media (max-width: 850px) {
  .contact {
    display: block;
  }
  .contact-call {
    padding: 15px;
  }

  .ex-images{
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .open-text-2 h2 {
    margin: 0;
  }

  .right-part-choose h2{
    margin:0;
  }
  .right-part-choose p{
    margin: 0;
    padding: 10px;
  }
 
}
@media (max-width:520px) {
  .contact {
    display: block;
  }
  .contact-call {
    padding: 15px;
  }

  .ex-images{
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  /* .open-text-2 h2 {
    margin: 0;
  } */

  .right-part-choose h2{
    margin:0;
  }
  .right-part-choose p{
    margin: 0;
    padding: 5px;
  }
 
}

@media screen and (max-width: 840px) {
  .choose-boarding {
    flex-basis: 56%;
    display: block;

    justify-content: center;
    align-items: center;
  }
}
