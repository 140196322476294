.all-value{
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align:center

}
.all-value h1 {
 color: #98344E; 
 margin-top: 30px;  
}

.sections{

    margin-left: 25em;
     justify-content: center;
     align-items: center;
}

.value{
    padding: 10px;
    max-width: 400px;
    margin: 20px;
}
   

.value h3{
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #98344E; 
}

.value img{
    width: 20%;
    align-items: flex-start;
    justify-content: flex-start;
}
    
    
    
@media screen and (max-width: 840px) {
    .sections{

        margin-left: 3em;
        /* display: flex;
        flex-direction: column; */
         justify-content: center;
         align-items: center;
    }

    .value img{
        width: 10%;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .value{
        padding: 10px;
        max-width: 400px;
        margin: 15px;
    }
  }



