/* src/ApplicationForm.css */
.application-form {
    width: 50%;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
   
  }
  
  .logo {
    display: block;
    margin: 0 auto 20px;
    width: 30%;
    height: 30%;
  }
  
  h2 {
    text-align: center;
    color: #98344e;
    padding-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input, textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #98344e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #98344e;
  }
  span {
    color: green;
    text-align: center;
    display: block; 
    margin-top: 20px; 
  }