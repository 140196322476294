.home-title{
    color: #98344E;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    
}

.home-title h1{
    font-size: 32px;
    color: #98344E;
    text-transform: none;
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    font-weight: 400;
    /* margin-left: 40px; */
    padding-top: 10px;
    padding-left: 30px;
}


.title-hr{
    margin-left: 70px;
}