.careers{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("../../assests/schoolImages/studentImage2.jpg");
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
.career-info h1{
  color: #98344e;
  text-align: flex-start;
}
    
    .careers-txt{
        text-align:flex-start;
        max-width: 800px;
    }
    
    .careers-txt h1{
        text-align:flex-start;
        font-size: 1.9em ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
    }
 

/* Default styles */
.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .filters {
    margin-bottom: 20px;
  }
  
  .filters label {
    margin-right: 10px;
  }
  
  .job-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    gap: 20px;
  }
  .job-list button {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
  }
  
  .job {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
  }
  .job h2{
    color: #4056AA;
    font-size: 16px;

  }
  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .app {
      padding: 10px;
    }
  
    .filters {
      margin-bottom: 10px;
    }
  
    .filters label {
      margin-right: 5px;
    }
  
    .job-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 10px;
    }
  }

  .career-info{
    max-width: 80%;
    justify-content: center;
    margin-top: 10px;
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    font-size: 18px;
    font-weight: 300px;
    margin-left: 60px;
    margin-right: 60px;
  }

.career-info p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;

}
  .career-info h3{
      padding: 15px;
      text-align: center;
      color: #4056AA;

      
      }
  
  @media screen and (max-width: 480px) {
    .app {
      padding: 5px;
    }
  
    .filters {
      margin-bottom: 5px;
    }
  
    .filters label {
      margin-right: 3px;
    }
  
    .job-list {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 5px;
    }
  
    .job {
      padding: 8px;
    }
  }
  