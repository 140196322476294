@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 4px 10px;
  font-family: "Roboto", Helvetica, arial, sans-serif;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 1s ease;
}


.nav img {
  width: 200px;
  padding-top: 0;
  /* padding: 10px; */
  margin-right: 30px;
  margin-top: 0;
}
.fa-firstdraft {
  margin-left: 1.5rem;
  font-size: 1.6rem;
}
.nav-menu {
  display: flex;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: flex-end;
  /* margin-right: 1rem; */
  align-items: center;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  margin-top: 0;

}

.nav-links {
  color: #fff;
  text-decoration: none;
  font-weight: 250px;
  padding: 1rem 1rem;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-style: inherit;
  margin-top: 0;
}
.dark-nav-links{
  color: #98344E;
  text-decoration: none;
  font-weight: 250px;
  padding: 1rem 1rem;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-style: inherit;
  margin-top: 0;
}

.nav-links:hover {
  /* background-color: #C00022;
  border-radius: 4px; so
   */
  border-bottom: 1px solid #98344e;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #98344e;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.dark-nav {
  background-color: #fff;

}

@media screen and (max-width: 960px) {
    .nav {
      flex-direction: column; 
      align-items: flex-start; 
      padding-left: 10px;
      padding-top: 10px;
    }
  

  .nav-logo {
    margin-left: 0px;
    padding-left: 0;
    align-self: flex-start; 
  }

  .nav-menu {
    display: block; 
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: 80%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-bottom: 40px;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: start;
    padding-right: 1.5rem;
    width: 90%;
    display: block;
    color: #000000;
  }

  .dark-nav-links {
    text-align: center;
    padding: 1rem 5px;
    width: 100%;
    display: block;
    color: #000000;
    flex-direction: fixed;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #98344e;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: none;
    text-align: start;
    padding: 1.5rem;
    border-radius: 4px;
    width: 80%;
    background: #cbced1;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-item {
    display: flex;
    align-items: start;
    height: 80px;
    margin-top: 0;  
    margin-left: 150px;
  }

  .nav-links-mobile:hover {
    background: #98344e;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
    margin-bottom: 50px;
  }
}
.dropdown-menu {
  background-color: #fff;
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  border: 1px solid #ceced8;
  display: inline-block;
}

.dropdown-menu li {
  /* color: #686565; */
  cursor: pointer;
}

.dropdown-menu li:hover {
  transition: all 0.2s ease-out;
  border-bottom: solid 1px #98344E;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #222020;
  padding: 10px;
  font-family: 'Roboto', Helvetica, arial, sans-serif; 
  font-weight: 300;
}

@media screen and (max-width: 960px) {
  .dropdown-menu {
    position: absolute;
    width:50%;
    text-align: start; /* Adjust alignment */
    border: 1px solid #df5836;
    display: inline-block; /* Initially hide the dropdown menu */
    padding-right: 50px;
    padding: 0;
  }

  .dropdown-menu.active {
    display: -webkit-box; /* Display the dropdown menu when active */
  }

  .dropdown-menu li {
    cursor: pointer;
  }

  .dropdown-menu li:hover {
    transition: all 0.2s ease-out;
    border-bottom: solid 1px #98344E;
   
  }

  .dropdown-link {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #222020;
    padding: 10px;
    font-family: 'Roboto', Helvetica, arial, sans-serif; 
    font-weight: 300;
    margin-right: 200px;
    margin-bottom: 20px;
  }
}

.btn {
  background-color: #98344E;
  color: #fff;
  padding: 8px 40px;
  font-size: 15px;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 1px;
  border: solid #fff 1px;
  font-weight: 250;
  min-width: 179px;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn:hover {
  background-color: #98344E;
  color: white;
}

/* Media Queries for Responsive Design */
@media (max-width: 820px) {
  .enquire {
    height: auto;
  }

  .btn {
    max-width:  30%;
    text-align: center;
    -webkit-text-size-adjust: 12px;
       -moz-text-size-adjust: 12px;
            text-size-adjust: 12px;
  }

  hr {
    width: 60%;
  }
}

@media (max-width: 560px) {
  .btn {
    max-width: 20%;
    -webkit-text-size-adjust: 12px;
       -moz-text-size-adjust: 12px;
            text-size-adjust: 12px;
   
   
   
  }

  hr {
    width: 50%;
  }
}

@media (max-width: 420px) {
  .enquire {
    height: 400px;
  }

  .btn {
  
    max-width: 10%;
    -webkit-text-size-adjust: 12px;
       -moz-text-size-adjust: 12px;
            text-size-adjust: 12px;
   
  }

  hr {
    width: 40%;
  }
}
.footer {
    background: transparent;
    flex-direction: column;
}

.sb_footer {
    display: flex;
    flex-direction: column;
}

.sb__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    padding: 10px;
}

.sb__footer-links_div {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    color: #98344E;
    font-style: normal;
    align-items: flex-start;
}

a {
    color: #000000;
    text-decoration: none;
}

.socialmedia {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.socialmedia img {
    width: 15%;
    padding-right: 15px;
}

.pin img {
    width: 50%;
    padding-top: 20px;
  
}

.sb__footer-links_div h4 {
    font-size: 15px;
    line-height: 14px;
    margin-bottom: 0.7em;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding-right: 50px;
    max-width: 400px;
    text-align: left;
}

.logo-image {
    width: 150px;
    margin-right: 30px;
}

.sb__footer-links_div p {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    letter-spacing: 1;
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    padding-bottom: 8px;
   
    text-align: left;
}

.pin p {
    
    text-align: left;
}

.sb__footer-below {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    background: #E8d881;
    padding: 25px;
}

.sb__footer-below h3 {
    font-size: 16px;
    line-height: 14px;
    margin-bottom: 0.7em;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #fff;
}

.sb__footer-below-links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sb__footer-below-links p {
    color: #000000;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    letter-spacing: 1;
    font-size: 17px;
    font-weight: 300;
}

.sb__footer-copy-right h3 {
    font-size: 16px;
    line-height: 15px;
    margin-left: 2rem;
    color: #000000;
    font-weight: 400;
}

hr {
    color: white !important;
    width: 100%;
}

.sb_footer-copyright p {
    font-size: 17px;
    line-height: 15px;
    color: rgb(255, 255, 255);
    font-weight: 600;
}

/* Media Queries */
@media screen and (max-width: 720px) {
    .sb__footer-heading h1 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .sb__footer-links_div h4 {
        margin-top: 28px;
        max-width: 300px;
    }

    .sb__footer-heading h3 {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }

    .logo-image {
        width: 120px;
        padding: 15px;
    }
}

@media screen and (max-width: 550px) {
    .sb__footer-heading h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .sb__footer-links-div {
        margin: 1rem 0;
        align-items: center;
    }

    .sb__footer-btn p {
        font-size: 14px;
        line-height: 15px;
    }

    .sb__footer-links_div {
        padding: 15px;
    }

    .sb__footer-links_div p {
        margin-left: 0;
    }

    .sb__footer-below-links p {
        flex-direction: column;
        margin-top: 8em;
    }
}

@media screen and (max-width: 400px) {
    .sb__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }  
}

@media screen and (max-width: 720px) {
    .sb__footer-below {
        flex-direction: column;
        align-items: center;
        padding: 12px;
    }

    .sb__footer-copy-right {
        text-align: center;
    }

    .sb__footer-below-links {
        margin-top: 0.5rem;
        padding: 15px;
    }

    .sb__footer-below p {
        padding: 5px;
    }
}

/* Ensure no horizontal scroll */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
  
  /* Hero section styling */
  .hero {
    width: 100%;
    min-height: 90vh; /* Adjust the height as needed */
    background: linear-gradient(rgba(8, 0, 18, 0.4), rgba(8, 0, 18, 0.4)), url(/static/media/48.bffe9df8.jpg);    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
  
    /* Center content */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; /* Center text inside the hero section */
  }
  
  /* Hero text styling */
  .hero-txt {
    max-width: 80%; /* Adjusted for better responsiveness */
    padding: 20px;
  }
  
  .hero-txt h1 {
    font-size: 2.5rem; /* Responsive font size */
    font-weight: 600;
  }
  
  .hero-txt p {
    max-width: 100%;
    margin: 10px auto 20px;
    line-height: 1.4;
    font-size: 1rem; /* Responsive font size */
  }
  
  /* Ensure images are responsive */
  img {
    max-width: 100%; /* Ensure images do not overflow */
    height: auto; /* Maintain aspect ratio */
    color: pink; /* If needed for styling */
  }
  
  @media (max-width: 768px) {
    .hero-txt h1 {
        font-size: 1.8rem; /* Adjust font size for smaller screens */
    }
    
    .hero-txt p {
        font-size: 0.9rem; /* Adjust font size for smaller screens */
    }
}
  
/* Media query for mobile screens */
@media (max-width: 768px) {
    .home-component {
      padding: 10px; /* Reduce padding for smaller screens */
    }
  
    .home-component > * {
      max-width: 100%; /* Allow full-width components on small screens */
      margin-bottom: 15px; /* Adjust space between sections for mobile */
    }
  
    /* Optional: Adjust font sizes for better readability on small screens */
    h1, h2, h3, h4, p {
      font-size: 16px; /* Adjust font size for better readability */
    }
  
    .loading-container {
      padding: 0;
    }
  }
  
  /* Media query for very small screens */
  @media (max-width: 480px) {
    .home-component {
      padding: 5px; /* Further reduce padding for very small screens */
    }
  
    .home-component > * {
      margin-bottom: 10px; /* Reduce space between sections further */
    }
  
    /* Optional: Further adjust font sizes for very small screens */
    h1, h2, h3, h4, p {
      font-size: 14px; /* Further adjust font size for very small screens */
    }
  }
.history-1 {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   width: 100%;
 }
 
 .history-left-1 {
   flex-basis: 35%;
   position: relative;
   margin-top: 30px;
 }
 
 .history-right {
   flex-basis: 56%;
   margin-right: 20px;
 }
 
 .history-img-1 {
   width: 100%;
   height: auto;
   margin-left: 150px;
 }
 
 .play-icon {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   cursor: pointer;
   width: 35%;
 }
 
 .history-1 h1 {
   font-weight: 400;
   font-size: 32px;
   color: #98344E;
   max-width: 600px;
   margin-top: 15px;
   text-align: start;
 }
 
 .history-right-home p {
   font-family: 'Roboto', Helvetica, arial, sans-serif;
   letter-spacing: 1px;
   font-size: 18px;
   font-weight: 300;
   max-width: 550px;
   margin-right: 105px;
   text-align: start;
 }
 
 @media (max-width: 800px) {
   .history-1 {
     flex-direction: column;
     align-items: center;
     margin: 5%;
   }
 
   .history-left-1 {
     flex-basis: 100%;
     order: 1;
     max-width: 100%;
   }
 
   .history-right {
     flex-basis: 100%;
   }
 
   .history-left-1 img {
     max-width: 100%;
     flex-basis: 100%;
   }
 
   .history-img-1 {
     margin: 0 auto;
     max-width: 100%;
     padding-right: 0;
   }
 
   .play-icon {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
   }
 
   .history-right-home p {
    
     text-align: start;
   }
 
   .history-1 h1 {
     font-size: 28px;
     text-align: start;
     margin-left: 0;
   }
 }
 
/* Prevent horizontal scroll */
body {
   margin: 0;
   padding: 0;
   overflow-x: hidden;
 }
 
 .programs {
   margin: 80px auto;
   width: 90%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   box-sizing: border-box; /* Include padding and border in the element's total width and height */
   padding-left: 40px; /* Replace margin-left */
   padding-right: 40px; /* Replace margin-right */
   flex-wrap: wrap; /* Allow flex items to wrap */
 }
 
 .programs p {
   color: #fff;
   letter-spacing: 1px;
   font-size: 14px;
   font-weight: 300;
 }
 
 .learn-more p {
   color: #fff;
   letter-spacing: 1px;
   font-size: 28px;
   font-weight: 500;
   font-family: 'Roboto', Helvetica, Arial, sans-serif;
 }
 
 .program img {
   width: 100%;
   /* border-radius: 10px; */
   display: inline;
 }
 
 .program {
   flex-basis: 30%;
   position: relative;
   box-sizing: border-box; /* Include padding and border in the element's total width and height */
   margin-bottom: 20px; /* Add margin bottom for spacing */
 }
 
 .program button {
   width: 100%;
   padding: 10px;
   color: #fff;
   background-color: #98344E;
   border: none;
   height: auto;
   font-size: 16px;
   cursor: pointer;
 }
 
 .caption {
   /* border-radius: 10px; */
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("");
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   color: #ffffff;
   cursor: pointer;
   font-size: small;
   opacity: 0;
   padding-top: 60%;
   transition: 0.4s;
   font-family: 'Roboto', Helvetica, Arial, sans-serif;
   font-weight: 300;
 }
 
 .caption p {
   font-size: 20px;
   font-weight: 400;
 }
 
 .caption img {
   width: 40px;
   margin-bottom: 10px;
 }
 
 .program:hover .caption {
   opacity: 1;
   padding-top: 0;
 }
 
 .btn:hover {
   background-color: #98344E; /* Change background color on hover */
 }
 
 /* Make button responsive */
 @media screen and (max-width: 840px) {
   .program button {
     font-size: 16px; /* Adjust font size */
     padding: 10px; /* Adjust padding */
   }
 }
 
 @media screen and (max-width: 840px) {
   .programs {
     flex-direction: column;
     padding-left: 20px; /* Adjust padding for smaller screens */
     padding-right: 20px; /* Adjust padding for smaller screens */
   }
 
   .program {
     flex-basis: 100%; /* Full width on smaller screens */
     margin: 20px 0;
   }
 }
 
 @media screen and (max-width: 550px) {
   .learn-more p {
     font-size: 24px; /* Adjust font size */
   }
 
   .caption p {
     font-size: 18px; /* Adjust font size */
   }
 
   .program button {
     font-size: 14px; /* Adjust font size */
     padding: 10px; /* Adjust padding */
   }
 }
 
 @media screen and (max-width: 400px) {
   .learn-more p {
     font-size: 20px; /* Adjust font size */
   }
 
   .caption p {
     font-size: 16px; /* Adjust font size */
   }
 
   .program button {
     font-size: 14px; /* Adjust font size */
     padding: 10px; /* Adjust padding */
   }
 }
 
.title{
    text-align: center;
    color: #212EA0;
    font-size: 15px;
    font-weight: 600;
    /* align-items: center; */
    display: flex;
    justify-content: center;
    
}

.heading h1{
    font-size: 32px;
    color: #98344E;;
    text-transform: none;
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    font-weight: 400;
    padding-left: 10px;
}
.heading-1 {
  font-weight: 400;
  font-size:  32px;
  color: #98344E;
  max-width: 450px;
}

.icon{
  font-size: 32px;
  color: #98344E;
  margin-top: 5px;
  text-transform: none;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  font-weight: 400;
}
  
    .line{
      border: solid 1px;
      color: #E8D881;
      margin: 10px;
      width: 100%;
      background-color: #fff;
  
  
  } 
 


.Message {
    width: 85%;
    justify-content: space-between;
    display: flex;
    margin: 15px 77px; /* Adjusted margin for better spacing */
  }
  
  .Message img{
    height:65%;
    width:100%; 
    margin-top: 0;
    margin-bottom: 20px;

}
  .Message p {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 300;
  }
  
  .messageSection h4{
    text-align:  start;
    justify-content: flex-start;
    margin: 0;
    color:#98344E
  }
  .imageSection {
    flex-basis: 46%;
    padding-top: 40px;
    margin-left: 0;
    display: flex; /* Added display flex */
    justify-content: center; /* Center horizontally */
    /* align-items: center; Center vertically */
  }
  
  .messageSection {
    flex-basis: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .messageSection h3 {
    font-weight: 350;
    font-size: 2.1rem;
    color: #98344E;
    max-width: 400px;
    padding-top: 15px;
  }
  .messageSection h5{
    padding-top: 10px;
   
  }
  
  @media (max-width: 850px) {
    .Message {
      display: block;
      margin-left: 40px;
    }

  
    .imageSection img{
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
 
  
   
    .right-section-choose p{
      margin: 0;
      padding: 100px;
    }
   
  }
  @media (max-width:520px) {
    .Message {
      display: block;
      margin-left: 20px;
    }
   
    .imageSection img{
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
   
    .messageSection p{
      margin: 0;
      padding: 5px;
    }
   
  }
  

.innovationSection{
    /* margin:  20px; */
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    width: 95%;
    padding-top: 60px;
   

}
.innovationSection img{
   width: 140%;
   /* border-radius: 7px; */
   height: 95%;
   /* margin-left: 40%; */
    
}

.section-left{
   flex-basis: 56%;
   position: relative;
   padding: 25px;
   padding-left: 8%;
 

}
.section-right{
    position: relative;
    padding: 40px;
    flex-basis: 55%;
    padding-right: 10%;
 }

 .section-right img{
 
   padding-left: 30px;
   padding-top: 100px;
   height: 100%;
 
}

 .innovationSection h1{
  font-size: 32px;
  color: #98344E;
  text-transform: none;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  font-weight: 400;
  max-width: 400px;
  text-align: start;

 }

 .section-left p{
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  padding-top: 10px;
    
 }

  @media (max-width: 840px){
   .innovationSection{
     display: block;
     margin-left: 40;
     margin-right: 40px;
   }
   .section-left{
      flex-basis: 100%;
      margin: 5px ;
   }

   .section-right{
      flex-basis: 100%;
      margin-left: 20px;
    
   }
   .section-right img{
     margin: 0;
     padding: 0;
     align-items: center;
     max-width: 100%;
     
    
   }
 }


 @media (max-width: 540px){
   .innovationSection{
      display: block;
      margin-left: 20;
      margin-right: 20px;
   }
   .section-left{
      flex-basis: 100%;
      margin: 15px ;
   }

   .section-right{
      flex-basis: 100%;
      order:1 ;
   }
 }

/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", Helvetica, arial, sans-serif;
  box-sizing: border-box;
}

.history-s {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  width: 80%;
}

/* Left section with image */
.history-left-s {
  flex: 1 1 48%;
  padding: 10px;
  padding-left: 0;
  text-align: start;
}

.history-left-s img.smart-img {
  width: 100%;
  height: auto;
  max-width: 600px; 
  
}

/* Right section with text */
.history-right-s {
  flex: 1 1 48%;
  padding: 10px;
}

.history-right-s h1 {
  font-size: 32px;
  color: #98344E;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 20px;
}

.history-right-s p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  padding-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .history-s {
    flex-direction: column;
    padding: 10px;
  }

  .history-left-s, .history-right-s {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .history-right-s h1 {
    font-size: 28px;
    text-align: start;
  }

  .history-right-s p {
   
    text-align: start;
    padding-left: 0;
    font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  padding-top: 10px;
  }
}

@media (max-width: 600px) {
  .history-right-s h1 {
    font-size: 24px;
  }

  .history-right-s p {
    font-size: 18px;
  }
}

body {
  margin-left: 15px;
  margin-right: 15px;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.contact{
  margin: 80px auto;
  max-width: 90%;
  display: flex;
  align-content: center;
  justify-content: space-between;

}
.contact-call{
  flex-basis: 48%;
  color: #676767;

}
.contact-call h2{
  font-weight: 350;
  font-size:  2.1rem;
  color: #98344E;
  max-width: 400px;
  padding-top: 20px;
  align-items: center;
  text-align: start;

}
.contact-call h2 img{
  width: 15px;
  margin-left: 10px;
  fill: #E8D881;

}
.contact-call p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
}

.contact-call  ul li {

  display: flex;
  align-items: center;
  margin: 20px 0;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  padding-top: 5px;
  font-weight: 300;
}

.contact-call ul li img{
   width: 25px;
   margin-right: 10px ;


}

.contact form input,
.contact form textarea,
.contact form button{
  display: block;
  width: 100%;
  background: #fff;
  color: #676767;
  padding: 15px;
  border-radius:10px;
  border: solid 1px #E8D881 ;
  outline: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  resize: none;
  font-family:'Roboto', Helvetica, arial, sans-serif;
}


.contact-call span{
  display: block;
  margin: 20px 0;
}

.btn-1{
  border: #E8D881;
}



/* Responsive Design */
@media (max-width: 840px) {
  .contact {
    display: block;
  }

  .contact-call {
    flex-basis: 100%; /* Full width on smaller screens */
    padding: 20px;
  }
}

@media (max-width: 550px) {
  .contact-call h2 {
    font-size: 34px;
    line-height: 42px;
  }

  .contact-call p {
    font-size: 15px;
    line-height: 20px;
  }

  .sb__footer-links_div p {
    align-items: flex-start;
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .contact-call h2 {
    font-size: 27px;
    line-height: 38px;
  }

  .contact-call p {
    font-size: 14px;
    line-height: 18px;
  }

  .contact-call ul li {
    font-size: 14px;
    line-height: 18px;
    margin: 15px 0;
  }

  .contact form input,
  .contact form textarea,
  .contact form button {
    padding: 10px;
    /* padding-left: 0; */
  }
}
.enquire {
  background-color: #E8D881;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 210px;
  text-align: center;
  margin-top: 30px;
}

.enquire h1 {
  padding: 20px;
  color: #fff;
  letter-spacing: 1px;
}

.enquire p {
  padding: 20px;
  color: #fff;
  font-weight: 350;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}


.container {
  display: flex;
  justify-content: center; /* Horizontally center */
}

hr {
  width: 60%;
  background-color: #fff;
}

.enquire button {
  border-radius: 30px;
  border: solid 1px #fff;
  width: 20%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.enquire button:hover {
  background-color: #da5679;
  color: #fff;
}

/* Media Queries for Responsive Design */
@media (max-width: 820px) {
  .enquire button {
      width: 40%;
  }

  hr {
      width: 60%;
  }
  .btn h5{
    font-size: 12px;
    padding: 5px;
  }
}

@media (max-width: 560px) {
  .enquire button {
      width: 45%;
  }

  hr {
      width: 50%;
  }
  .btn h5{
    font-size: 12px;
    padding: 5px;
  }
}

@media (max-width: 420px) {
  .enquire {
      height: 400px;
  }

  .enquire button {
      width: 100%;
  }
  .btn h5{
    font-size: 12px;
    padding: 5px;
  }

  hr {
      width: 40%;
  }
}

.home-title{
  color: #98344E;
  font-size: 15px;
  font-weight: 400;
  /* display: flex; */
  
}

.home-title h1{
  font-size: 32px;
  color: #98344E;
  text-transform: none;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  font-weight: 400;
  margin-left: 40px;
}


.home-heading hr{
font-size: clamp(1.875rem, 1.6040462427746rem + 1.4450867052023vw, 2.5rem) !important;
line-height: 1.25 !important;
letter-spacing: 1px !important;
width: 800px;
margin-left: 30px;

} 

.lines{
 
font-size: clamp(1.875rem, 1.6040462427746rem + 1.4450867052023vw, 2.5rem) !important;
line-height: 1.25 !important;
letter-spacing: 1px !important;
width: 500px;
margin-left: 30px;
}


.video-player{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}


.video-player video {
    width: 90%;
    max-width: 900px;
    height :auto ;
    border:  4px solid  #fff;
}

.video-player.hide {
    display: none;
}


.line {
  width: 90%;
  margin: 0 auto; 
  border: none; 
  border-top: 1.5px solid #E8D881; 
  
}

.about{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url(/static/media/152.10d6b71a.jpg);
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
    
    .about-txt{
        text-align:flex-start;
        max-width: 800px;
    }
    
    .about-us h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        /* margin-left: 0; */
    }
    .hero-txt p{
        max-width: 900px ; 
        margin: 10px auto 20px;
        line-height: 1.4;
    }

    /* ...............media query............ */

    @media (max-width: 650px){
        .contact{
            display: block;
        }
        .contact-call{
            padding: 15px;
        } 
        .about-us h1{
            text-align:flex-start;
            font-size: 30px ;
            font-weight: 600px;
            letter-spacing: 3;
            /* margin-left: 0; */
        }
        
    }

.home-title{
    color: #98344E;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    
}

.home-title h1{
    font-size: 32px;
    color: #98344E;
    text-transform: none;
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    font-weight: 400;
    /* margin-left: 40px; */
    padding-top: 10px;
    padding-left: 30px;
}


.title-hr{
    margin-left: 70px;
}
.all-value{
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align:center

}
.all-value h1 {
 color: #98344E; 
 margin-top: 30px;  
}

.sections{

    margin-left: 25em;
     justify-content: center;
     align-items: center;
}

.value{
    padding: 10px;
    max-width: 400px;
    margin: 20px;
}
   

.value h3{
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #98344E; 
}

.value img{
    width: 20%;
    align-items: flex-start;
    justify-content: flex-start;
}
    
    
    
@media screen and (max-width: 840px) {
    .sections{

        margin-left: 3em;
        /* display: flex;
        flex-direction: column; */
         justify-content: center;
         align-items: center;
    }

    .value img{
        width: 10%;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .value{
        padding: 10px;
        max-width: 400px;
        margin: 15px;
    }
  }




.about-school{
    margin-left:  30px;
    margin-right: 30px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    background-color: #fff1;
}

.about-school-right{
   flex-basis: 64%;
   /* position: relative; */
   width: 150px;
   height: 250px;
   border: 1px solid #98344E;
   

}
.about-school-section{
    flex-basis: 40%;
    padding-left: 0px;

 }
 .about-school-section h1{
 color: #98344E;
 text-align: start;

}

 .history-img{
   width: 600px;
   height: 460px;
   padding-top: 30px;
   padding-bottom: 20px;

   
 }
 .about-school p{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1;
    font-size: 18px;
    padding-top: 15px;
    font-weight: 300;

    
 }
 .about-school-section{
   flex-basis: 60%;
   padding-right: 50px;
 }



 @media (max-width: 800px){
   .about-school{
      flex-direction: column;
   }
   .about-shool-section{
      flex-basis: 100%;
      margin: 30px ;
   }

   .about-school-right{
      flex-basis: 100%;
    
   }
 

 }





.careers{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url(/static/media/studentImage2.e2244930.jpg);
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
.career-info h1{
  color: #98344e;
  text-align: flex-start;
}
    
    .careers-txt{
        text-align:flex-start;
        max-width: 800px;
    }
    
    .careers-txt h1{
        text-align:flex-start;
        font-size: 1.9em ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
    }
 

/* Default styles */
.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .filters {
    margin-bottom: 20px;
  }
  
  .filters label {
    margin-right: 10px;
  }
  
  .job-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  .job-list button {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  
  .job {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
  }
  .job h2{
    color: #4056AA;
    font-size: 16px;

  }
  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .app {
      padding: 10px;
    }
  
    .filters {
      margin-bottom: 10px;
    }
  
    .filters label {
      margin-right: 5px;
    }
  
    .job-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 10px;
      gap: 10px;
    }
  }

  .career-info{
    max-width: 80%;
    justify-content: center;
    margin-top: 10px;
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    font-size: 18px;
    font-weight: 300px;
    margin-left: 60px;
    margin-right: 60px;
  }

.career-info p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;

}
  .career-info h3{
      padding: 15px;
      text-align: center;
      color: #4056AA;

      
      }
  
  @media screen and (max-width: 480px) {
    .app {
      padding: 5px;
    }
  
    .filters {
      margin-bottom: 5px;
    }
  
    .filters label {
      margin-right: 3px;
    }
  
    .job-list {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 5px;
      gap: 5px;
    }
  
    .job {
      padding: 8px;
    }
  }
  
/* src/ApplicationForm.css */
.application-form {
    width: 50%;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
   
  }
  
  .logo {
    display: block;
    margin: 0 auto 20px;
    width: 30%;
    height: 30%;
  }
  
  h2 {
    text-align: center;
    color: #98344e;
    padding-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input, textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #98344e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #98344e;
  }
  span {
    color: green;
    text-align: center;
    display: block; 
    margin-top: 20px; 
  }
.school-life{
  width: 100%;
  min-height: 80vh;
  color: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url(/static/media/91.fecfaea8.jpg);
  background-size: cover;
  background-position: center ;
  background-repeat: no-repeat;
}
  
  .school-life-txt{
      text-align:flex-start;
      max-width: 800px;
  }
  
  .school-life-txt h1{

      font-size: 30px ;
      font-weight: 600px;
      letter-spacing: 1px;
      margin-top: 450px;
  }

.school-life h1{
  text-align: start;
}

.container-1 {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  h4 {
    text-align: center;
    margin-bottom: 20px;
    color: #98344E;
    text-decoration: none;
    align-items: center;

  }
  .loading-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;     /* Centers vertically */
    min-height: 100vh;       
   
  }
  
  .loading-gif {
    width: 70px;  
    height: 70px; 
  }
  
  
  .form-container-1 {
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
   
  .card-container-1 {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
  }
  
  .card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(33.333% - 20px); /* Adjust width to fit 3 cards per row */
    box-sizing: border-box;
    text-align: center;
  }
  
  .card img {
    width: 100%;
    height: 50%;
    border-radius: 4px;
  }
  
  .card-title {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .card-description {
    font-size: 1em;
    color: #555;
  }
  
  .read-more {
    color: #98344E;
    cursor: pointer;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  
  .card-date {
    margin-top: 10px;
    color: #888;
  }
  
 .card-container-1 h4{
    text-align:  center;

  }
.admission-process{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url(/static/media/45.d1edfb0f.JPG);
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
    
    .about-txt{
        text-align:flex-start;
        max-width: 800px;
    }
    .about-us h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
    }
    .hero-txt p{
        max-width: 900px ; 
        margin: 10px auto 20px;
        line-height: 1.4;
    }

    /* ...............media query............ */

    @media (max-width: 650px){
        .contact{
            display: block;
        }
        .contact-call{
            padding: 15px;
        } 
        
    }

.admission_process{
    display: flex;
    flex-direction: column;
    text-align: start;
    max-width: 500px;
    position: relative;
    margin-left: 35%;
   

}

.admission_process h1{
   color: #98344E;
   font-size: 1.5em;
   text-align: start;
   padding-top: 10px;
   padding-bottom: 10px;
 
}

.admission_process p{
   font-family: 'Roboto', Helvetica, arial, sans-serif;
   letter-spacing: 1px;
   font-size: 18px;
   font-weight: 300;
   margin: 10px;
 
} 


.apply-btn{
   border-radius: 30px;
   max-width: 130px;
   padding-left: 15px;
   padding-right: 15px;
   padding-top: 10px;
   padding-bottom: 10px;
   border-color: #98344e;
   border-width: 0.5px;
   color: #fff;
   background-color: #98344e;
   cursor: pointer;
}


.apply-btn:hover{
   background-color: #98344e;
   color: #fff;
   border-color: #fff;
}

@media (max-width: 970px) {
   .admission_process{
      display: block;
      margin-left:  35%;
   }
   .step_1 button {
      border-radius: 30px;
      max-width: 400px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-color: #98344e;
      border-width: 0.5px;
      color: #98344e;
      background-color: #fff;
      cursor: pointer; 
   }
 
 }
 
 @media (max-width: 820px) {
   .apply-btn{
   
     border-radius: 30px;
     text-align: center;
    
     margin-bottom: 10px;
     align-items: center;
     border: solid 1px #fff ;
     width: 50%;
    
     
   }
 
 }


.why-choose-us{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url(/static/media/S-4.2edb72e2.jpg);
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
    
    .about-txt{
        text-align:flex-start;
        max-width: 800px;
    }
    
    .choose-us h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
    }
    .hero-txt p{
        max-width: 900px ; 
        margin: 10px auto 20px;
        line-height: 1.4;
    }

    /* ...............media query............ */

    @media (max-width: 650px){
        .contact{
            display: block;
        }
        .contact-call{
            padding: 15px;
        } 
        
    }

.why-choose-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
.why-choose-us-container h1{
  color: #98344e;
}

.reasons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.reason-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: calc(33% - 20px);
  margin: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.reason-card:hover {
  transform: translateY(-5px);
}

.reason-icon {
  font-size: 2em;
  color: #E8D881;
  margin-bottom: 10px;
}

.reason-name {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.reason-description {
  font-size: 1em;
  color: #666;
}

.read-more {
  color: #98344e;
  cursor: pointer;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .reason-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .reason-card {
    width: calc(100% - 20px);
  }
}

.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 400px;
}

.slide {
  /* border-radius: 0.5rem; */
  box-shadow: 0px 0px 7px #666;
  width: 100%;
  height: 100%;
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #c00022);
  width: 2rem;
  height: 2rem;
  color: white;

}

.arrow:hover {
  cursor: pointer;
}

.arrow-left {
  left: 0;
}

.arrow-right {
  right:0;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: #c00022;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: grey;
}
/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .carousel {
    max-width: 100%;
    aspect-ratio: 16 / 9; /* Adjust aspect ratio for smaller screens */
  }

  .indicator {
    height: 0.4rem;
    width: 0.4rem;
  }
}

@media (max-width: 480px) {
  .carousel {
    aspect-ratio: 1 / 1; /* Adjust aspect ratio for very small screens */
  }

  .indicator {
    height: 0.3rem;
    width: 0.3rem;
  }
}

/* Ensure no horizontal scroll */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.choose-boarding img{
  width: 80%;
  height: 100%;

}

.choose-boarding{
  margin: 20px;
  display: flex;
  align-items:normal ;
  justify-content: space-between;
  width: 100%;

}

.boarding{
  width: 100%;
  min-height: 80vh;
  color: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  background: linear-gradient(rgba(8, 0, 32, 0.4), rgba(8, 0, 32, 0.4)), url(/static/media/310.d52a22db.jpg);
  background-size: cover;
  background-position: center ;
  background-repeat: no-repeat;
}
  
  .about-boarding{
      text-align:flex-start;
      max-width: 800px;
  }

.about-boarding h1 {
  text-align:flex-start;
  font-size: 30px ;
  font-weight: 600px;
  letter-spacing: 3;
  margin-top: 450px;
}


.open-text-1 h2 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  margin-left: 30px;
  color: #98344e;
  text-align: flex-start;
}

.open-text-3 h2 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  margin-left: 30px;
  color: #98344e;
  text-align: flex-start;
}
.open-text-3 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
  padding-bottom: 15px;
}

.left-part-choose{
  margin-left: 40px;
}

.left-part-choose p{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 300;
    margin: 0;
    padding-left: 30px;
    max-width: 600px;
}

.right-part-choose p{

    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 300;
    margin: 0;
    padding-right: 30px;
    max-width: 600px;
    padding-left: 30px;
}
.right-part-choose {
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
}

.left-part-choose {
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 25px;
  
}


.right-part-choose h2 {
  padding-left: 0;
  margin-left: 0;
  text-align: flex-start;
  color: aqua;
 
}
.left-part-choose h2{
  padding-left: 0;
  margin-left: 0;
  text-align: flex-start;
  /* padding-left: 30px; */
}
.choose-elements h1 {
  font-weight: 350;
  font-size: 2.1rem;
  color: #98344e;
  max-width: 400px;
  padding-top: 15px;
}

.choose-boarding h2{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  color: #98344e;
  text-align: flex-start;
  margin: 0;
  padding-top: 10px;
  

  
}
.choose-boarding p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  text-align: flex-start;
  margin: 0;
  padding-left: 0;
  

  
}

.open-text-1 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
}


.left-part-choose img{
  align-content: center;
  height: 85%;
  width: 100%;
}

.right-part-choose img{
  align-content: center;
  height: 90%;
  width: 100%;
  padding:25px;
  
}

.ex-images {
  margin: 4em;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.ex-images img {
  height: 300px;
  width: 400px;
  padding: 15px;
} 

.open-text-2 h2 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  margin-left: 30px;
  color: #98344e;
  text-align: flex-start;
}

.open-text-2 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
  margin-left: 30px;
  margin-bottom:30px;
}

/* ...............media query............ */

@media (max-width: 850px) {
  .contact {
    display: block;
  }
  .contact-call {
    padding: 15px;
  }

  .ex-images{
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .open-text-2 h2 {
    margin: 0;
  }

  .right-part-choose h2{
    margin:0;
  }
  .right-part-choose p{
    margin: 0;
    padding: 10px;
  }
 
}
@media (max-width:520px) {
  .contact {
    display: block;
  }
  .contact-call {
    padding: 15px;
  }

  .ex-images{
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  /* .open-text-2 h2 {
    margin: 0;
  } */

  .right-part-choose h2{
    margin:0;
  }
  .right-part-choose p{
    margin: 0;
    padding: 5px;
  }
 
}

@media screen and (max-width: 840px) {
  .choose-boarding {
    flex-basis: 56%;
    display: block;

    justify-content: center;
    align-items: center;
  }
}

/* Ensure no horizontal scroll */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}
.preschool {
  width: 100%;
  min-height: 80vh;
  color: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)),url(/static/media/P-3-main.22fd35c0.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.about-preschool {
  text-align: flex-start;
  max-width: 800px;
}


.history {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px;
  width: 80%;
}


.history-left, .history-right {
  flex: 1 1 48%; /* Flex-grow, flex-shrink, flex-basis */
  box-sizing: border-box;
  padding: 10px;
}

.history-left {
  max-width: 50%;
}

.history-right {
  max-width: 50%;
}








.about-preschool h1 {
  text-align: flex-start;
  font-size: 30px;
  font-weight: 600px;
  letter-spacing: 3;
  margin-top: 450px;
  /* border-bottom: solid 1px #c00022; */
}

.preschool-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.preschool h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 30px;
  padding-left: 25px;
  color: #98344e;
}

.pre-right-section-choose h2 {
  display: block;
  font-size: 1.5em;
  margin-right: 92px;
  color: #98344e;
  font-style: normal;
}
.history-right h2{
  display: block;
  font-size: 1.5em;
  color: #98344e;
  font-style: normal;
  margin-left: 50px;
  text-align:start;
}
.history-right p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 50px;
}

.history-right li {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 90px;
}

.pre-choose-us{
  margin: 20px;
  display: flex;
  align-items:normal ;
  justify-content: space-between;
  width: 100%;

}

.pre-right-section-choose {
  flex-basis: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 105px;

}

.pre-left-section-choose {
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 20px;
  
  }
  .pre-right-section-choose img{
    align-content: center;
    height: 90%;
    width: 100%;
    padding:25px;
   
    
  }

.pre-left-section-choose h1{
  padding-left: 10px;
  color: #98344e;
}
  .pre-left-section-choose p{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 300;
    padding-right: 30px;
    max-width: 600px;
    padding-left: 10px;
   
 ;
}

.pre-left-section-choose h2{
  padding-top: 10px;
}


.open-text-h2 h2 {
  display: block;
  font-size: 2.2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  margin-left: 30px;
  text-align: flex-start;
  color: #98344e;
}

.open-text-3 h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  margin-left: 30px;
  color: #98344e;
}
.open-text-3 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 30px;
}

.pre-right-section-choose p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
 
}
.choose-elements h1 {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  /* text-align: center; */
  font-weight: 400;
  padding-top: 10px;
  color: #98344e;
}

.open-text-1 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 30px;
}
.open-text-20 h2{
  padding-top: 5px;
  
}


.images {
  margin: 4em;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.images img {
  height: 350px;
  width: 500px;
  padding: 15px;
  margin-top: 5px;
}

.open-text-h2 h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  /* font-weight: bold; */
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
 
  color: #98344e;
  font-style: normal;
}

.open-text-h2 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 30px;
}

/* ..........media query......... */
@media (max-width: 850px) {
  .contact {
    display: block;
  }
  .contact-call {
    padding: 15px;
  }
  .pre-choose-us{
   
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    align-items: flex-start;
    padding-top: 25px;
    padding-bottom: 25px;
  
  }

}
@media (max-width: 768px) {
  .history {
    flex-direction: column;
    align-items: flex-start;
  }

  .history-left, .history-right {
    max-width: 100%;
  }

  .history-right h2, .history-right p, .history-right li {
    text-align: start;
  }

  .history-right p {
    margin-top: 10px;
  }
}
/* Ensure no horizontal scroll */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
.prischool{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url(/static/media/89.bb630d6d.jpg);
    background-size: cover;

    background-repeat: no-repeat;
    background-position-y:-10px;
   background-position-y: center;
}
    .about-prischool{
        text-align:flex-start;
        max-width: 800px;

    }
    .about-prischool h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
        /* border-bottom: solid 1px #c00022; */
    }

/* All the css for  */

.pri-choose-us{
    margin: 25px;
    margin-top: 35px;
    display: flex;
    align-items:normal ;
    justify-content: space-between;
    width: 100%;
  }



  .pri-choose-section{
    margin: 25px;
    margin-top: 35px;
    display: flex;
    align-items:normal ;
    justify-content: space-between;
    width: 100%;
  }


  .pri-left-section-choose{
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content:  start;
    align-items: flex-start;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pri-right-section-choose {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 105px;
  
  }

  .pri-right-section-choose img{
    align-content: center;
    height: 90%;
    width: 100%;
    padding:25px;
   margin-top: 75px;
  }
  .pri-left-section-choose p{
    font-family: "Roboto", Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 300;
  }
      



    .left-section-choose{
        margin: 0;
    }
     
    .left-section-pri img{
        width: 600px;
        height: 460px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 40px;
      
      }
      .left-section-pri{
        flex-basis: 58%;
        display: block;
        margin-left:70px;
      }

      .why-choose-pri{
        flex-basis: 62%;
      }
      
      .why-choose-pri h2{
        text-align: start;
        padding-bottom: 5px;
      }
      .why-choose-pri p {
        font-family: "Roboto", Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 300;
        max-width: 500px;
        /* margin-left: 30px; */
      }
   

    .primary-text-1  h2{
        display: block;
        font-size: 1.80em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: -webkit-isolate;
        unicode-bidi: isolate;
        margin-left: 30px;
        color: #98344E;
        text-align: start;

    }

    .why-choose-pri li{
      font-family: "Roboto", Helvetica, arial, sans-serif;
      letter-spacing: 1px;
      font-size: 18px;
      padding-top: 5px;
      font-weight: 300;
      padding-bottom: 8px;
      padding-left: 40px;
      
    }
    .primary-text-3 h2{
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: -webkit-isolate;
        unicode-bidi: isolate;
        margin-left: 30px;
        color: #98344E;
        text-align: start;

    }
    .primary-text-3 p{
        font-family: "Roboto", Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 300;
        margin-left: 30px;
        /* padding-bottom: 10px; */
    
        }

    .choose-elements h1{
        font-family: 'Roboto', Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        text-align: start;
        font-weight: 400;
        padding-top: 10px;
        color: #98344E;
    }
  



    .primary-text-1 p{
        font-family: "Roboto", Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 300;
        margin-left: 30px;
    }

    
    .images{
        margin: 4em;
        display: flex;
        justify-content:space-between;
        align-content: center;

    }

    .images h3{
        color: #98344E;
        text-align: center;
    }
    .choose-us img{
     height: 20px;
    }

    .primary-text-2  h2{
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: -webkit-isolate;
        unicode-bidi: isolate;
        margin-left: 30px;
        color: #98344E;
        text-align: start;
       

    }

  .primary-text-2 p{
    font-family: "Roboto", Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 300;
    margin-left: 30px;
    padding-bottom: 20px;
    }

    /* ...............media query............ */

    @media (max-width: 650px){
        .contact{
            display: block;
        }
        .contact-call{
            padding: 15px;
        } 
        
    }
    @media (max-width: 840px) {
        .pri-choose-section{
          flex-basis: 67%;
          display: block;
          justify-content: flex-start;
          align-items: flex-start;
          margin-left: 0;
          padding-left: 20px;
          padding-right: 20px;
        }
        .pri-left-section-choose p {
          margin-left: 0;
          margin-right: 0;
          max-width: 600px;
         
        }
        .pri-left-section-choose h2{
          padding-top: 10px;
          padding-bottom: 10px;
          
      
        }
        .left-section-pri {
            flex-basis: 100%;
            margin-left: 0;
            margin-right: 10px;
        }
      
        .pri-left-section-choose{
          flex-basis: 60%;
          margin-right: 60px;
          margin-left: 60px;
      
        }
        .why-choose-pri h2{
            padding-top: 10px;
            padding-bottom: 10px;
            
        
          }
  

      }
      @media (max-width: 840px) {
        .pri-choose-us{
            flex-basis: 67%;
            display: block;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 0;
            padding-left: 20px;
            padding-right: 20px;
        }
        .why-choose-pri p{
            margin-left: 0;
            margin-right: 0;
            max-width: 600px;
        }
      }
/* Ensure no horizontal scroll */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.jssschool {
  width: 100%;
  min-height: 70vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)),url(/static/media/208.2832828a.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.about-jssschool {
  text-align: flex-start;
  max-width: 800px;
}



.juni-choose-us{
  margin: 20px;
  display: flex;
  align-items:normal ;
  justify-content: space-between;
  width: 100%;

}

.juni-left-section-choose {
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 25px;
  
  }
  .juni-right-section-choose {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 105px;
  
  }
  .juni-left-section-choose {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    align-items: flex-end;
    padding-top: 25px;
    padding-bottom: 25px;
    
    }
    .juni-right-section-choose img{
      align-content: center;
      height: 90%;
      width: 100%;
      padding:25px;
      padding-top: 75px;
     
    }
  
  .juni-left-section-choose h1{
    padding-left: 10px;
    color: #98344e;
  }
    .juni-left-section-choose p{
      font-family: 'Roboto', Helvetica, arial, sans-serif;
      letter-spacing: 1px;
      font-size: 18px;
      padding-top: 5px;
      font-weight: 300;
      padding-right: 30px;
      max-width: 600px;
     
     
   ;
  }
  
  .juni-left-section-choose h2{
    padding-top: 10px;
  }
  
.about-jssschool h1 {
  text-align: flex-start;
  font-size: 30px;
  font-weight: 600px;
  letter-spacing: 3;
  margin-top: 450px;
  /* margin-top: 10px; */
  /* border-bottom: solid 1px #c00022; */
}

.open-text-1 h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-top: 7px;
  color: #98344e;
}
.open-text-1 p{
    margin-top: 10px;
    margin-left: 30px;
    margin-bottom: 30px;
}

.open-text-3 h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  /* margin-top: 10px; */
  color: #98344e;
}
.open-text-3 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
}

.juni-choose-section {
  margin: 10px;
  display: flex;
  align-items:normal ;
  justify-content: space-between;
  width: 100%;
}

.juni-why-section-choose{
  flex-basis: 67%;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 25px;

}
.juni-why-section-choose img{
  align-content: center;
  height: 95%;
  width: 100%;
  padding:25px;
 

}
.juni-why-choose-section {
  flex-basis: 67%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 55px;
  padding-top: 75px;
}
.juni-why-choose-section p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
  padding-right: 30px;
  max-width: 600px;

}


.choose-left-section p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  max-width: 500px;
  margin-left: 30px;
  
}
.choose-left-section h2{
    margin-top: 15px;
    text-align: start;

}

.open-text-20 h2{
  margin-left: 25px;
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-top: 30px;
  color: #98344e;
  text-align: start;
}

.open-text-20 p{
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 25px;
  margin-right: 25px;
}

.why-choose-section h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-top: 30px;
  color: #98344e;
  text-align: flex-start;
}
.choose-elements h1 {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  text-align: flex-start;
  font-weight: 400;
  padding-top: 10px;
  color: #98344e;
}

.about-school-left{
    flex-basis: 40%;
    padding-left: 0px;

 }
 .about-school-left h1{
 color: #98344E;

}

 .why-choose-right-section img{
   width: 600px;
   height: 460px;
   padding-top: 30px;
   padding-bottom: 20px;
   padding-right: 40px;
 
 }

.why-choose-right-section{
    flex-basis: 35%;
    padding-left: 0px;
}

.images {
  margin: 4em;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.images img {
  height: 300px;
  width: 400px;
  padding: 15px;
  margin-top: 5px;
}
.whychoose-image {
  width: 60%;
  height: 50%;
  margin-left: 20px;
  flex-basis: 46%;
  align-items: center;
}
.open-text-2j h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 30px;
  padding-left: 25px;
  color: #98344e;
  text-align: flex-start;
}

.open-text-2j p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
  padding-left: 25px;
}

.images {
  margin: 40px auto;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.images p {
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 300;
}

li{
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  padding-top: 5px;
  font-weight: 300;
  padding-left: 30px;
 
  color: #98344E;
  
}
.learn-more p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
}
.program-images img {
  width: 100%;
  /* border-radius: 10px; */
  display: inline;
  height: 50%;
}
.program-images button{
  width: 100%;
}

.program-images {
  flex-basis: 30%;
  position: relative;
}

.caption {
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  cursor: pointer;
  font-size: small;
  opacity: 0;
  padding-top: 60%;
  transition: 0.4s;
  font-family: "Roboto", Helvetica, arial, sans-serif;
  font-weight: 300;
}

.caption p {
  font-size: 20px;
  font-weight: 400;
}
.caption img {
  width: 40px;
  margin-bottom: 10px;
}
.program-images:hover .caption {
  opacity: 1;
  padding-top: 0;
}
.btn-1 {
  background-color: #98344e;
  color: #fff;
  padding: 8px 40px;
  font-size: 15px;
  width: 90%;
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 2px;
  border: solid #fff 1px;
  font-weight: 400;
  /* line-height: 1.875rem; */
  min-width: 170px;
  align-content: center;
  transition: background-color 0.3s, color 0.3s;
}
.btn:hover {
  background-color: #98344e; /* Change background color on hover */
}

/* Make button responsive */
@media screen and (max-width: 840px) {
  .btn {
    font-size: 20px;
    font-weight: 400;
  }
}
@media screen and (max-width: 840px) {
  .juni-choose-us {
    flex-basis: 56%;
    display: block;

    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 840px) {
  .juni-choose-section{
    flex-basis: 67%;
    display: block;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
  }
  .juni-why-choose-section p {
    margin-left: 0;
    margin-right: 0;
    max-width: 600px;
   
  }
  .juni-why-choose-section h2{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;

  }

  .juni-why-section-choose{
    flex-basis: 60%;
    margin-right: 60px;

  }

}


@media (max-width: 840px) {
  .images {
    flex-direction: column;
  }
  .program-images {
    flex-basis: 60%;
    margin: 40px;
  }

  .juni-left-section-choose{
    order: 2;
  }

  
  .juni-left-section-choose img {
    margin-right: 50px;
    margin-left: 50px;
   
  }

  .open-text-2 h2{
    margin-left: 30px;
    margin-right: 30px;
    
  
  }

  .juni-why-choose-right-section img {
    margin-right: 50px;
    margin-left: 50px;
  }
}






/* ...............media query............ */

@media (max-width: 650px) {
  .contact {
    display: block;
  }
  .contact-call {
    padding: 15px;
  }
}

.alumni{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.08), rgba(8, 0, 58, 0.08)), url(/static/media/studentImage4.cbfa46cb.jpg);
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
    background-color: blue;
}
    
    .alumni-txt{
        text-align:flex-start;
        max-width: 800px;
      
    }
    
    .alumni-txt h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;

    }
.career-info p{
    padding-bottom: 30px;
}
@media (max-width: 650px){
    .contact{
        display: block;
    }
    .contact-call{
        padding: 15px;
    } 
    .about-us h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        /* margin-left: 0; */
    }
    
}

/* Ensure no horizontal scroll */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
.philosophy{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url(/static/media/36.91f3a5d5.jpg);
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
    
    .our_philosophy{
        text-align:flex-start;
        max-width: 800px;
    }
    .our_philosophy h1{
        text-align:flex-start;
        font-size: 1.8em ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
    }

    .school_philosophy p{
        font-family: "Roboto", Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 300;
        margin-left: 30px;
        padding-bottom: 15px;
        }

        .school_philosophy h1{
            text-align:flex-start;
            font-size: 1.9em ;
            font-weight: 300px;
            letter-spacing: 3;
            margin-left: 30px;
            color: #98344E;
            padding-top: 15px;
            padding-bottom: 15px;
        }
 

.privacy-sections{
    margin-top: 150px;
    margin-left: 30px;



}
.all-sections{
padding: 10px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
text-align: start;
max-width: 800px;
justify-content: flex-start;

}
.all-sections h1{
    font-weight: 400;
    font-size:  20px;
    color: #98344E;
    max-width: 450px;
    text-align: start;
}

.all-sections p{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding: 5px;
    font-weight: 300;
    max-width: 800px;
}
.all-sections li{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 5px;
    font-weight: 300;
    max-width: 800px;
    padding-left: 20px;
}
.careers{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url(/static/media/studentImage2.e2244930.jpg);
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
.career-info h1{
  color: #98344e;
  text-align: flex-start;
}
    
    .careers-txt{
        text-align:flex-start;
        max-width: 800px;
    }
    
    .careers-txt h1{
        text-align:flex-start;
        font-size: 1.9em ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
    }
 

/* Default styles */
.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .filters {
    margin-bottom: 20px;
  }
  
  .filters label {
    margin-right: 10px;
  }
  
  .job-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  .job-list button {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  
  .job {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
  }
  .job h2{
    color: #4056AA;
    font-size: 16px;

  }
  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .app {
      padding: 10px;
    }
  
    .filters {
      margin-bottom: 10px;
    }
  
    .filters label {
      margin-right: 5px;
    }
  
    .job-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 10px;
      gap: 10px;
    }
  }

  .career-info{
    max-width: 80%;
    justify-content: center;
    margin-top: 10px;
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    font-size: 18px;
    font-weight: 300px;
    margin-left: 60px;
    margin-right: 60px;
  }

.career-info p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;

}
  .career-info h3{
      padding: 15px;
      text-align: center;
      color: #4056AA;

      
      }
  
  @media screen and (max-width: 480px) {
    .app {
      padding: 5px;
    }
  
    .filters {
      margin-bottom: 5px;
    }
  
    .filters label {
      margin-right: 3px;
    }
  
    .job-list {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 5px;
      gap: 5px;
    }
  
    .job {
      padding: 8px;
    }
  }
  
/* Container for the form */
.news-event-form {
width:30%;
  margin: auto; /* Center the form horizontally */
  padding: 20px; /* Padding around the content */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for 3D effect */
  background-color: #ffffff; /* White background for the form */
  align-content: center;
}

/* Header inside the form */
.news-event-form h2 {
  text-align: center; /* Center the heading text */
  margin-bottom: 20px; /* Space below the heading */
  font-size: 1.5em; /* Slightly larger font size for emphasis */
  color: #333; /* Dark grey color for the heading */
}

/* Grouping form elements */
.form-group {
  margin-bottom: 15px; 
  max-width: 400px; /* Maximum width of the form */
  max-height: 600px;
}

/* Label styling */
.form-group label {
  display: block; /* Makes label occupy full width */
  margin-bottom: 5px; /* Space below the label */
  font-weight: bold; /* Makes label text bold */
  color: #333; /* Dark grey color for the label */
}

/* Input and textarea styling */
.form-group input,
.form-group textarea {
  width: 60%; /* Full width of the parent container */
  padding: 8px; /* Padding inside the input and textarea */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 4px; /* Rounded corners */
  box-sizing: border-box; 
  align-items: center;
}

/* Error styling for input fields */
.form-group .error {
  border-color: red; /* Red border color for error states */
}

/* Error message styling */
.form-group .error-message {
  color: red; /* Red text color for error messages */
  font-size: 0.875em; /* Slightly smaller font size */
}

/* Button styling */
button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #98344e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Disabled button styling */
button:disabled {
  background-color: #ccc; /* Grey background color for disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled button */
}

/* Responsive adjustments for small screens */
@media (max-width: 600px) {
  .news-event-form {
    padding: 15px; /* Reduced padding on smaller screens */
  }

  .news-event-form h2 {
    font-size: 1.25em; /* Slightly smaller font size on mobile */
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.875em; /* Slightly smaller font size inside input and textarea */
  }

  button {
    padding: 12px; /* Slightly larger padding on small screens */
    font-size: 0.875em; /* Slightly smaller font size on button */
  }
}

.admin-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  

  h1{
    color: #98344e;;
  }
  h2{
    color: #98344e;;
  }


  .event-form {
    margin-bottom: 20px;
  }
  
.btn-position{
    margin-top: 100px;
}
  .admin-container button{
    width: 15%;
    background-color: #f4f4f4;
    color: #98344e;
  }
  .event-form h2 {
    margin-bottom: 10px;
  }
  
  .event-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .event-form input, .event-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  
  .event-form button {
    padding: 10px 15px;
    background-color:#98344e;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .event-form button:hover {
    background-color:#98344e;
  }
  
  .event-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .event-table th, .event-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .event-table th {
    background-color: #f4f4f4;
  }
  
  .event-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .event-table img.event-image {
    width: 50px;
    height: auto;
  }
  
  .event-table button {
    padding: 5px 10px;
    background-color: #98344e;;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .event-table button:hover {
    background-color: #98344e;;
  }
  
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    
  }
  
  .modal-content {
    background: white;
    padding: 20px;
  
    border-radius: 8px;
    width: 100%;
    max-width: 350px;
   
    position: relative;
  }
  
  .news-event-form {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .news-event-form h2 {
    margin-top: 0;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  /* .btn-primary {
    background-color: #007bff;
    color: white;
  } */
  
  .modal-content .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
   background-color:none ;
    color: #dc3545;
  }
  
.not-found h1{

text-align: center;

padding-top: 200px;
padding-bottom: 200px;

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
}
body{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
}


  .container {
    padding-left: 5%;
    padding-right: 5%;
}



@media (max-width: 960px){
 .container{
  padding-left: 0.5%;
  padding-right: 0.5%;
 } 
}
