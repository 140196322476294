.why-choose-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
.why-choose-us-container h1{
  color: #98344e;
}

.reasons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.reason-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: calc(33% - 20px);
  margin: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.reason-card:hover {
  transform: translateY(-5px);
}

.reason-icon {
  font-size: 2em;
  color: #E8D881;
  margin-bottom: 10px;
}

.reason-name {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.reason-description {
  font-size: 1em;
  color: #666;
}

.read-more {
  color: #98344e;
  cursor: pointer;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .reason-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .reason-card {
    width: calc(100% - 20px);
  }
}
