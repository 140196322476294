.container-1 {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  h4 {
    text-align: center;
    margin-bottom: 20px;
    color: #98344E;
    text-decoration: none;
    align-items: center;

  }
  .loading-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;     /* Centers vertically */
    min-height: 100vh;       
   
  }
  
  .loading-gif {
    width: 70px;  
    height: 70px; 
  }
  
  
  .form-container-1 {
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
   
  .card-container-1 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  
  .card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(33.333% - 20px); /* Adjust width to fit 3 cards per row */
    box-sizing: border-box;
    text-align: center;
  }
  
  .card img {
    width: 100%;
    height: 50%;
    border-radius: 4px;
  }
  
  .card-title {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .card-description {
    font-size: 1em;
    color: #555;
  }
  
  .read-more {
    color: #98344E;
    cursor: pointer;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  
  .card-date {
    margin-top: 10px;
    color: #888;
  }
  
 .card-container-1 h4{
    text-align:  center;

  }