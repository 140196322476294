.home-title{
  color: #98344E;
  font-size: 15px;
  font-weight: 400;
  /* display: flex; */
  
}

.home-title h1{
  font-size: 32px;
  color: #98344E;
  text-transform: none;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  font-weight: 400;
  margin-left: 40px;
}


.home-heading hr{
font-size: clamp(1.875rem, 1.6040462427746rem + 1.4450867052023vw, 2.5rem) !important;
line-height: 1.25 !important;
letter-spacing: 1px !important;
width: 800px;
margin-left: 30px;

} 

.lines{
 
font-size: clamp(1.875rem, 1.6040462427746rem + 1.4450867052023vw, 2.5rem) !important;
line-height: 1.25 !important;
letter-spacing: 1px !important;
width: 500px;
margin-left: 30px;
}

