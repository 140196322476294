.footer {
    background: transparent;
    flex-direction: column;
}

.sb_footer {
    display: flex;
    flex-direction: column;
}

.sb__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    padding: 10px;
}

.sb__footer-links_div {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    color: #98344E;
    font-style: normal;
    align-items: flex-start;
}

a {
    color: #000000;
    text-decoration: none;
}

.socialmedia {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.socialmedia img {
    width: 15%;
    padding-right: 15px;
}

.pin img {
    width: 50%;
    padding-top: 20px;
  
}

.sb__footer-links_div h4 {
    font-size: 15px;
    line-height: 14px;
    margin-bottom: 0.7em;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding-right: 50px;
    max-width: 400px;
    text-align: left;
}

.logo-image {
    width: 150px;
    margin-right: 30px;
}

.sb__footer-links_div p {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    letter-spacing: 1;
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    padding-bottom: 8px;
   
    text-align: left;
}

.pin p {
    
    text-align: left;
}

.sb__footer-below {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    background: #E8d881;
    padding: 25px;
}

.sb__footer-below h3 {
    font-size: 16px;
    line-height: 14px;
    margin-bottom: 0.7em;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #fff;
}

.sb__footer-below-links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sb__footer-below-links p {
    color: #000000;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    letter-spacing: 1;
    font-size: 17px;
    font-weight: 300;
}

.sb__footer-copy-right h3 {
    font-size: 16px;
    line-height: 15px;
    margin-left: 2rem;
    color: #000000;
    font-weight: 400;
}

hr {
    color: white !important;
    width: 100%;
}

.sb_footer-copyright p {
    font-size: 17px;
    line-height: 15px;
    color: rgb(255, 255, 255);
    font-weight: 600;
}

/* Media Queries */
@media screen and (max-width: 720px) {
    .sb__footer-heading h1 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .sb__footer-links_div h4 {
        margin-top: 28px;
        max-width: 300px;
    }

    .sb__footer-heading h3 {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }

    .logo-image {
        width: 120px;
        padding: 15px;
    }
}

@media screen and (max-width: 550px) {
    .sb__footer-heading h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .sb__footer-links-div {
        margin: 1rem 0;
        align-items: center;
    }

    .sb__footer-btn p {
        font-size: 14px;
        line-height: 15px;
    }

    .sb__footer-links_div {
        padding: 15px;
    }

    .sb__footer-links_div p {
        margin-left: 0;
    }

    .sb__footer-below-links p {
        flex-direction: column;
        margin-top: 8em;
    }
}

@media screen and (max-width: 400px) {
    .sb__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }  
}

@media screen and (max-width: 720px) {
    .sb__footer-below {
        flex-direction: column;
        align-items: center;
        padding: 12px;
    }

    .sb__footer-copy-right {
        text-align: center;
    }

    .sb__footer-below-links {
        margin-top: 0.5rem;
        padding: 15px;
    }

    .sb__footer-below p {
        padding: 5px;
    }
}
