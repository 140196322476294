/* Container for the form */
.news-event-form {
width:30%;
  margin: auto; /* Center the form horizontally */
  padding: 20px; /* Padding around the content */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for 3D effect */
  background-color: #ffffff; /* White background for the form */
  align-content: center;
}

/* Header inside the form */
.news-event-form h2 {
  text-align: center; /* Center the heading text */
  margin-bottom: 20px; /* Space below the heading */
  font-size: 1.5em; /* Slightly larger font size for emphasis */
  color: #333; /* Dark grey color for the heading */
}

/* Grouping form elements */
.form-group {
  margin-bottom: 15px; 
  max-width: 400px; /* Maximum width of the form */
  max-height: 600px;
}

/* Label styling */
.form-group label {
  display: block; /* Makes label occupy full width */
  margin-bottom: 5px; /* Space below the label */
  font-weight: bold; /* Makes label text bold */
  color: #333; /* Dark grey color for the label */
}

/* Input and textarea styling */
.form-group input,
.form-group textarea {
  width: 60%; /* Full width of the parent container */
  padding: 8px; /* Padding inside the input and textarea */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 4px; /* Rounded corners */
  box-sizing: border-box; 
  align-items: center;
}

/* Error styling for input fields */
.form-group .error {
  border-color: red; /* Red border color for error states */
}

/* Error message styling */
.form-group .error-message {
  color: red; /* Red text color for error messages */
  font-size: 0.875em; /* Slightly smaller font size */
}

/* Button styling */
button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #98344e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Disabled button styling */
button:disabled {
  background-color: #ccc; /* Grey background color for disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled button */
}

/* Responsive adjustments for small screens */
@media (max-width: 600px) {
  .news-event-form {
    padding: 15px; /* Reduced padding on smaller screens */
  }

  .news-event-form h2 {
    font-size: 1.25em; /* Slightly smaller font size on mobile */
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.875em; /* Slightly smaller font size inside input and textarea */
  }

  button {
    padding: 12px; /* Slightly larger padding on small screens */
    font-size: 0.875em; /* Slightly smaller font size on button */
  }
}
