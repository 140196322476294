.about-school{
    margin-left:  30px;
    margin-right: 30px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    background-color: #fff1;
}

.about-school-right{
   flex-basis: 64%;
   /* position: relative; */
   width: 150px;
   height: 250px;
   border: 1px solid #98344E;
   

}
.about-school-section{
    flex-basis: 40%;
    padding-left: 0px;

 }
 .about-school-section h1{
 color: #98344E;
 text-align: start;

}

 .history-img{
   width: 600px;
   height: 460px;
   padding-top: 30px;
   padding-bottom: 20px;

   
 }
 .about-school p{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1;
    font-size: 18px;
    padding-top: 15px;
    font-weight: 300;

    
 }
 .about-school-section{
   flex-basis: 60%;
   padding-right: 50px;
 }



 @media (max-width: 800px){
   .about-school{
      flex-direction: column;
   }
   .about-shool-section{
      flex-basis: 100%;
      margin: 30px ;
   }

   .about-school-right{
      flex-basis: 100%;
    
   }
 

 }




