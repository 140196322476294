.enquire {
  background-color: #E8D881;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 210px;
  text-align: center;
  margin-top: 30px;
}

.enquire h1 {
  padding: 20px;
  color: #fff;
  letter-spacing: 1px;
}

.enquire p {
  padding: 20px;
  color: #fff;
  font-weight: 350;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}


.container {
  display: flex;
  justify-content: center; /* Horizontally center */
}

hr {
  width: 60%;
  background-color: #fff;
}

.enquire button {
  border-radius: 30px;
  border: solid 1px #fff;
  width: 20%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.enquire button:hover {
  background-color: #da5679;
  color: #fff;
}

/* Media Queries for Responsive Design */
@media (max-width: 820px) {
  .enquire button {
      width: 40%;
  }

  hr {
      width: 60%;
  }
  .btn h5{
    font-size: 12px;
    padding: 5px;
  }
}

@media (max-width: 560px) {
  .enquire button {
      width: 45%;
  }

  hr {
      width: 50%;
  }
  .btn h5{
    font-size: 12px;
    padding: 5px;
  }
}

@media (max-width: 420px) {
  .enquire {
      height: 400px;
  }

  .enquire button {
      width: 100%;
  }
  .btn h5{
    font-size: 12px;
    padding: 5px;
  }

  hr {
      width: 40%;
  }
}
