
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
}
body{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
}


  .container {
    padding-left: 5%;
    padding-right: 5%;
}



@media (max-width: 960px){
 .container{
  padding-left: 0.5%;
  padding-right: 0.5%;
 } 
}