.Message {
    width: 85%;
    justify-content: space-between;
    display: flex;
    margin: 15px 77px; /* Adjusted margin for better spacing */
  }
  
  .Message img{
    height:65%;
    width:100%; 
    margin-top: 0;
    margin-bottom: 20px;

}
  .Message p {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 300;
  }
  
  .messageSection h4{
    text-align:  start;
    justify-content: flex-start;
    margin: 0;
    color:#98344E
  }
  .imageSection {
    flex-basis: 46%;
    padding-top: 40px;
    margin-left: 0;
    display: flex; /* Added display flex */
    justify-content: center; /* Center horizontally */
    /* align-items: center; Center vertically */
  }
  
  .messageSection {
    flex-basis: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .messageSection h3 {
    font-weight: 350;
    font-size: 2.1rem;
    color: #98344E;
    max-width: 400px;
    padding-top: 15px;
  }
  .messageSection h5{
    padding-top: 10px;
   
  }
  
  @media (max-width: 850px) {
    .Message {
      display: block;
      margin-left: 40px;
    }

  
    .imageSection img{
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
 
  
   
    .right-section-choose p{
      margin: 0;
      padding: 100px;
    }
   
  }
  @media (max-width:520px) {
    .Message {
      display: block;
      margin-left: 20px;
    }
   
    .imageSection img{
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
   
    .messageSection p{
      margin: 0;
      padding: 5px;
    }
   
  }
  