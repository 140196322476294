.school-life{
  width: 100%;
  min-height: 80vh;
  color: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("../../assests/Home/91.jpg");
  background-size: cover;
  background-position: center ;
  background-repeat: no-repeat;
}
  
  .school-life-txt{
      text-align:flex-start;
      max-width: 800px;
  }
  
  .school-life-txt h1{

      font-size: 30px ;
      font-weight: 600px;
      letter-spacing: 1px;
      margin-top: 450px;
  }

.school-life h1{
  text-align: start;
}
