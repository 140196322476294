.title{
    text-align: center;
    color: #212EA0;
    font-size: 15px;
    font-weight: 600;
    /* align-items: center; */
    display: flex;
    justify-content: center;
    
}

.heading h1{
    font-size: 32px;
    color: #98344E;;
    text-transform: none;
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    font-weight: 400;
    padding-left: 10px;
}
.heading-1 {
  font-weight: 400;
  font-size:  32px;
  color: #98344E;
  max-width: 450px;
}

.icon{
  font-size: 32px;
  color: #98344E;
  margin-top: 5px;
  text-transform: none;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  font-weight: 400;
}
  
    .line{
      border: solid 1px;
      color: #E8D881;
      margin: 10px;
      width: 100%;
      background-color: #fff;
  
  
  } 
 

