.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 4px 10px;
  font-family: "Roboto", Helvetica, arial, sans-serif;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 1s ease;
}


.nav img {
  width: 200px;
  padding-top: 0;
  /* padding: 10px; */
  margin-right: 30px;
  margin-top: 0;
}
.fa-firstdraft {
  margin-left: 1.5rem;
  font-size: 1.6rem;
}
.nav-menu {
  display: flex;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: flex-end;
  /* margin-right: 1rem; */
  align-items: center;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  margin-top: 0;

}

.nav-links {
  color: #fff;
  text-decoration: none;
  font-weight: 250px;
  padding: 1rem 1rem;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-style: inherit;
  margin-top: 0;
}
.dark-nav-links{
  color: #98344E;
  text-decoration: none;
  font-weight: 250px;
  padding: 1rem 1rem;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-style: inherit;
  margin-top: 0;
}

.nav-links:hover {
  /* background-color: #C00022;
  border-radius: 4px; so
   */
  border-bottom: 1px solid #98344e;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #98344e;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.dark-nav {
  background-color: #fff;

}

@media screen and (max-width: 960px) {
    .nav {
      flex-direction: column; 
      align-items: flex-start; 
      padding-left: 10px;
      padding-top: 10px;
    }
  

  .nav-logo {
    margin-left: 0px;
    padding-left: 0;
    align-self: flex-start; 
  }

  .nav-menu {
    display: block; 
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: 80%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-bottom: 40px;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: start;
    padding-right: 1.5rem;
    width: 90%;
    display: block;
    color: #000000;
  }

  .dark-nav-links {
    text-align: center;
    padding: 1rem 5px;
    width: 100%;
    display: block;
    color: #000000;
    flex-direction: fixed;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #98344e;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: none;
    text-align: start;
    padding: 1.5rem;
    border-radius: 4px;
    width: 80%;
    background: #cbced1;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-item {
    display: flex;
    align-items: start;
    height: 80px;
    margin-top: 0;  
    margin-left: 150px;
  }

  .nav-links-mobile:hover {
    background: #98344e;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
    margin-bottom: 50px;
  }
}