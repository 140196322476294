.admin-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  

  h1{
    color: #98344e;;
  }
  h2{
    color: #98344e;;
  }


  .event-form {
    margin-bottom: 20px;
  }
  
.btn-position{
    margin-top: 100px;
}
  .admin-container button{
    width: 15%;
    background-color: #f4f4f4;
    color: #98344e;
  }
  .event-form h2 {
    margin-bottom: 10px;
  }
  
  .event-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .event-form input, .event-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  
  .event-form button {
    padding: 10px 15px;
    background-color:#98344e;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .event-form button:hover {
    background-color:#98344e;
  }
  
  .event-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .event-table th, .event-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .event-table th {
    background-color: #f4f4f4;
  }
  
  .event-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .event-table img.event-image {
    width: 50px;
    height: auto;
  }
  
  .event-table button {
    padding: 5px 10px;
    background-color: #98344e;;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .event-table button:hover {
    background-color: #98344e;;
  }
  