.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    
  }
  
  .modal-content {
    background: white;
    padding: 20px;
  
    border-radius: 8px;
    width: 100%;
    max-width: 350px;
   
    position: relative;
  }
  
  .news-event-form {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .news-event-form h2 {
    margin-top: 0;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  /* .btn-primary {
    background-color: #007bff;
    color: white;
  } */
  
  .modal-content .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
   background-color:none ;
    color: #dc3545;
  }
  