.alumni{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.08), rgba(8, 0, 58, 0.08)), url("../../assests/schoolImages/studentImage4.jpg");
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
    background-color: blue;
}
    
    .alumni-txt{
        text-align:flex-start;
        max-width: 800px;
      
    }
    
    .alumni-txt h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;

    }
.career-info p{
    padding-bottom: 30px;
}
@media (max-width: 650px){
    .contact{
        display: block;
    }
    .contact-call{
        padding: 15px;
    } 
    .about-us h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        /* margin-left: 0; */
    }
    
}
