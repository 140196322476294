.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 400px;
}

.slide {
  /* border-radius: 0.5rem; */
  box-shadow: 0px 0px 7px #666;
  width: 100%;
  height: 100%;
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #c00022);
  width: 2rem;
  height: 2rem;
  color: white;

}

.arrow:hover {
  cursor: pointer;
}

.arrow-left {
  left: 0;
}

.arrow-right {
  right:0;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: #c00022;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: grey;
}
/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .carousel {
    max-width: 100%;
    aspect-ratio: 16 / 9; /* Adjust aspect ratio for smaller screens */
  }

  .indicator {
    height: 0.4rem;
    width: 0.4rem;
  }
}

@media (max-width: 480px) {
  .carousel {
    aspect-ratio: 1 / 1; /* Adjust aspect ratio for very small screens */
  }

  .indicator {
    height: 0.3rem;
    width: 0.3rem;
  }
}
