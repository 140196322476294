.history-1 {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   width: 100%;
 }
 
 .history-left-1 {
   flex-basis: 35%;
   position: relative;
   margin-top: 30px;
 }
 
 .history-right {
   flex-basis: 56%;
   margin-right: 20px;
 }
 
 .history-img-1 {
   width: 100%;
   height: auto;
   margin-left: 150px;
 }
 
 .play-icon {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   cursor: pointer;
   width: 35%;
 }
 
 .history-1 h1 {
   font-weight: 400;
   font-size: 32px;
   color: #98344E;
   max-width: 600px;
   margin-top: 15px;
   text-align: start;
 }
 
 .history-right-home p {
   font-family: 'Roboto', Helvetica, arial, sans-serif;
   letter-spacing: 1px;
   font-size: 18px;
   font-weight: 300;
   max-width: 550px;
   margin-right: 105px;
   text-align: start;
 }
 
 @media (max-width: 800px) {
   .history-1 {
     flex-direction: column;
     align-items: center;
     margin: 5%;
   }
 
   .history-left-1 {
     flex-basis: 100%;
     order: 1;
     max-width: 100%;
   }
 
   .history-right {
     flex-basis: 100%;
   }
 
   .history-left-1 img {
     max-width: 100%;
     flex-basis: 100%;
   }
 
   .history-img-1 {
     margin: 0 auto;
     max-width: 100%;
     padding-right: 0;
   }
 
   .play-icon {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
   }
 
   .history-right-home p {
    
     text-align: start;
   }
 
   .history-1 h1 {
     font-size: 28px;
     text-align: start;
     margin-left: 0;
   }
 }
 