.admission_process{
    display: flex;
    flex-direction: column;
    text-align: start;
    max-width: 500px;
    position: relative;
    margin-left: 35%;
   

}

.admission_process h1{
   color: #98344E;
   font-size: 1.5em;
   text-align: start;
   padding-top: 10px;
   padding-bottom: 10px;
 
}

.admission_process p{
   font-family: 'Roboto', Helvetica, arial, sans-serif;
   letter-spacing: 1px;
   font-size: 18px;
   font-weight: 300;
   margin: 10px;
 
} 


.apply-btn{
   border-radius: 30px;
   max-width: 130px;
   padding-left: 15px;
   padding-right: 15px;
   padding-top: 10px;
   padding-bottom: 10px;
   border-color: #98344e;
   border-width: 0.5px;
   color: #fff;
   background-color: #98344e;
   cursor: pointer;
}


.apply-btn:hover{
   background-color: #98344e;
   color: #fff;
   border-color: #fff;
}

@media (max-width: 970px) {
   .admission_process{
      display: block;
      margin-left:  35%;
   }
   .step_1 button {
      border-radius: 30px;
      max-width: 400px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-color: #98344e;
      border-width: 0.5px;
      color: #98344e;
      background-color: #fff;
      cursor: pointer; 
   }
 
 }
 
 @media (max-width: 820px) {
   .apply-btn{
   
     border-radius: 30px;
     text-align: center;
    
     margin-bottom: 10px;
     align-items: center;
     border: solid 1px #fff ;
     width: 50%;
    
     
   }
 
 }

