.dropdown-menu {
  background-color: #fff;
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  border: 1px solid #ceced8;
  display: inline-block;
}

.dropdown-menu li {
  /* color: #686565; */
  cursor: pointer;
}

.dropdown-menu li:hover {
  transition: all 0.2s ease-out;
  border-bottom: solid 1px #98344E;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #222020;
  padding: 10px;
  font-family: 'Roboto', Helvetica, arial, sans-serif; 
  font-weight: 300;
}

@media screen and (max-width: 960px) {
  .dropdown-menu {
    position: absolute;
    width:50%;
    text-align: start; /* Adjust alignment */
    border: 1px solid #df5836;
    display: inline-block; /* Initially hide the dropdown menu */
    padding-right: 50px;
    padding: 0;
  }

  .dropdown-menu.active {
    display: -webkit-box; /* Display the dropdown menu when active */
  }

  .dropdown-menu li {
    cursor: pointer;
  }

  .dropdown-menu li:hover {
    transition: all 0.2s ease-out;
    border-bottom: solid 1px #98344E;
   
  }

  .dropdown-link {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #222020;
    padding: 10px;
    font-family: 'Roboto', Helvetica, arial, sans-serif; 
    font-weight: 300;
    margin-right: 200px;
    margin-bottom: 20px;
  }
}
