body {
  margin-left: 15px;
  margin-right: 15px;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.contact{
  margin: 80px auto;
  max-width: 90%;
  display: flex;
  align-content: center;
  justify-content: space-between;

}
.contact-call{
  flex-basis: 48%;
  color: #676767;

}
.contact-call h2{
  font-weight: 350;
  font-size:  2.1rem;
  color: #98344E;
  max-width: 400px;
  padding-top: 20px;
  align-items: center;
  text-align: start;

}
.contact-call h2 img{
  width: 15px;
  margin-left: 10px;
  fill: #E8D881;

}
.contact-call p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
}

.contact-call  ul li {

  display: flex;
  align-items: center;
  margin: 20px 0;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  padding-top: 5px;
  font-weight: 300;
}

.contact-call ul li img{
   width: 25px;
   margin-right: 10px ;


}

.contact form input,
.contact form textarea,
.contact form button{
  display: block;
  width: 100%;
  background: #fff;
  color: #676767;
  padding: 15px;
  border-radius:10px;
  border: solid 1px #E8D881 ;
  outline: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  resize: none;
  font-family:'Roboto', Helvetica, arial, sans-serif;
}


.contact-call span{
  display: block;
  margin: 20px 0;
}

.btn-1{
  border: #E8D881;
}



/* Responsive Design */
@media (max-width: 840px) {
  .contact {
    display: block;
  }

  .contact-call {
    flex-basis: 100%; /* Full width on smaller screens */
    padding: 20px;
  }
}

@media (max-width: 550px) {
  .contact-call h2 {
    font-size: 34px;
    line-height: 42px;
  }

  .contact-call p {
    font-size: 15px;
    line-height: 20px;
  }

  .sb__footer-links_div p {
    align-items: flex-start;
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .contact-call h2 {
    font-size: 27px;
    line-height: 38px;
  }

  .contact-call p {
    font-size: 14px;
    line-height: 18px;
  }

  .contact-call ul li {
    font-size: 14px;
    line-height: 18px;
    margin: 15px 0;
  }

  .contact form input,
  .contact form textarea,
  .contact form button {
    padding: 10px;
    /* padding-left: 0; */
  }
}