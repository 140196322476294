/* Ensure no horizontal scroll */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
.prischool{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("../../assests/ps/89.jpg");
    background-size: cover;

    background-repeat: no-repeat;
    background-position-y:-10px;
   background-position-y: center;
}
    .about-prischool{
        text-align:flex-start;
        max-width: 800px;

    }
    .about-prischool h1{
        text-align:flex-start;
        font-size: 30px ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
        /* border-bottom: solid 1px #c00022; */
    }

/* All the css for  */

.pri-choose-us{
    margin: 25px;
    margin-top: 35px;
    display: flex;
    align-items:normal ;
    justify-content: space-between;
    width: 100%;
  }



  .pri-choose-section{
    margin: 25px;
    margin-top: 35px;
    display: flex;
    align-items:normal ;
    justify-content: space-between;
    width: 100%;
  }


  .pri-left-section-choose{
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content:  start;
    align-items: flex-start;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pri-right-section-choose {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 105px;
  
  }

  .pri-right-section-choose img{
    align-content: center;
    height: 90%;
    width: 100%;
    padding:25px;
   margin-top: 75px;
  }
  .pri-left-section-choose p{
    font-family: "Roboto", Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 300;
  }
      



    .left-section-choose{
        margin: 0;
    }
     
    .left-section-pri img{
        width: 600px;
        height: 460px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 40px;
      
      }
      .left-section-pri{
        flex-basis: 58%;
        display: block;
        margin-left:70px;
      }

      .why-choose-pri{
        flex-basis: 62%;
      }
      
      .why-choose-pri h2{
        text-align: start;
        padding-bottom: 5px;
      }
      .why-choose-pri p {
        font-family: "Roboto", Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 300;
        max-width: 500px;
        /* margin-left: 30px; */
      }
   

    .primary-text-1  h2{
        display: block;
        font-size: 1.80em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
        margin-left: 30px;
        color: #98344E;
        text-align: start;

    }

    .why-choose-pri li{
      font-family: "Roboto", Helvetica, arial, sans-serif;
      letter-spacing: 1px;
      font-size: 18px;
      padding-top: 5px;
      font-weight: 300;
      padding-bottom: 8px;
      padding-left: 40px;
      
    }
    .primary-text-3 h2{
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
        margin-left: 30px;
        color: #98344E;
        text-align: start;

    }
    .primary-text-3 p{
        font-family: "Roboto", Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 300;
        margin-left: 30px;
        /* padding-bottom: 10px; */
    
        }

    .choose-elements h1{
        font-family: 'Roboto', Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        text-align: start;
        font-weight: 400;
        padding-top: 10px;
        color: #98344E;
    }
  



    .primary-text-1 p{
        font-family: "Roboto", Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 300;
        margin-left: 30px;
    }

    
    .images{
        margin: 4em;
        display: flex;
        justify-content:space-between;
        align-content: center;

    }

    .images h3{
        color: #98344E;
        text-align: center;
    }
    .choose-us img{
     height: 20px;
    }

    .primary-text-2  h2{
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
        margin-left: 30px;
        color: #98344E;
        text-align: start;
       

    }

  .primary-text-2 p{
    font-family: "Roboto", Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 300;
    margin-left: 30px;
    padding-bottom: 20px;
    }

    /* ...............media query............ */

    @media (max-width: 650px){
        .contact{
            display: block;
        }
        .contact-call{
            padding: 15px;
        } 
        
    }
    @media (max-width: 840px) {
        .pri-choose-section{
          flex-basis: 67%;
          display: block;
          justify-content: flex-start;
          align-items: flex-start;
          margin-left: 0;
          padding-left: 20px;
          padding-right: 20px;
        }
        .pri-left-section-choose p {
          margin-left: 0;
          margin-right: 0;
          max-width: 600px;
         
        }
        .pri-left-section-choose h2{
          padding-top: 10px;
          padding-bottom: 10px;
          
      
        }
        .left-section-pri {
            flex-basis: 100%;
            margin-left: 0;
            margin-right: 10px;
        }
      
        .pri-left-section-choose{
          flex-basis: 60%;
          margin-right: 60px;
          margin-left: 60px;
      
        }
        .why-choose-pri h2{
            padding-top: 10px;
            padding-bottom: 10px;
            
        
          }
  

      }
      @media (max-width: 840px) {
        .pri-choose-us{
            flex-basis: 67%;
            display: block;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 0;
            padding-left: 20px;
            padding-right: 20px;
        }
        .why-choose-pri p{
            margin-left: 0;
            margin-right: 0;
            max-width: 600px;
        }
      }