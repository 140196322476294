.btn {
  background-color: #98344E;
  color: #fff;
  padding: 8px 40px;
  font-size: 15px;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 1px;
  border: solid #fff 1px;
  font-weight: 250;
  min-width: 179px;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn:hover {
  background-color: #98344E;
  color: white;
}

/* Media Queries for Responsive Design */
@media (max-width: 820px) {
  .enquire {
    height: auto;
  }

  .btn {
    max-width:  30%;
    text-align: center;
    text-size-adjust: 12px;
  }

  hr {
    width: 60%;
  }
}

@media (max-width: 560px) {
  .btn {
    max-width: 20%;
    text-size-adjust: 12px;
   
   
   
  }

  hr {
    width: 50%;
  }
}

@media (max-width: 420px) {
  .enquire {
    height: 400px;
  }

  .btn {
  
    max-width: 10%;
    text-size-adjust: 12px;
   
  }

  hr {
    width: 40%;
  }
}