/* Media query for mobile screens */
@media (max-width: 768px) {
    .home-component {
      padding: 10px; /* Reduce padding for smaller screens */
    }
  
    .home-component > * {
      max-width: 100%; /* Allow full-width components on small screens */
      margin-bottom: 15px; /* Adjust space between sections for mobile */
    }
  
    /* Optional: Adjust font sizes for better readability on small screens */
    h1, h2, h3, h4, p {
      font-size: 16px; /* Adjust font size for better readability */
    }
  
    .loading-container {
      padding: 0;
    }
  }
  
  /* Media query for very small screens */
  @media (max-width: 480px) {
    .home-component {
      padding: 5px; /* Further reduce padding for very small screens */
    }
  
    .home-component > * {
      margin-bottom: 10px; /* Reduce space between sections further */
    }
  
    /* Optional: Further adjust font sizes for very small screens */
    h1, h2, h3, h4, p {
      font-size: 14px; /* Further adjust font size for very small screens */
    }
  }