/* Ensure no horizontal scroll */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
  
  /* Hero section styling */
  .hero {
    width: 100%;
    min-height: 90vh; /* Adjust the height as needed */
    background: linear-gradient(rgba(8, 0, 18, 0.4), rgba(8, 0, 18, 0.4)), url("../../../assests/Home/48.jpg");    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
  
    /* Center content */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; /* Center text inside the hero section */
  }
  
  /* Hero text styling */
  .hero-txt {
    max-width: 80%; /* Adjusted for better responsiveness */
    padding: 20px;
  }
  
  .hero-txt h1 {
    font-size: 2.5rem; /* Responsive font size */
    font-weight: 600;
  }
  
  .hero-txt p {
    max-width: 100%;
    margin: 10px auto 20px;
    line-height: 1.4;
    font-size: 1rem; /* Responsive font size */
  }
  
  /* Ensure images are responsive */
  img {
    max-width: 100%; /* Ensure images do not overflow */
    height: auto; /* Maintain aspect ratio */
    color: pink; /* If needed for styling */
  }
  
  @media (max-width: 768px) {
    .hero-txt h1 {
        font-size: 1.8rem; /* Adjust font size for smaller screens */
    }
    
    .hero-txt p {
        font-size: 0.9rem; /* Adjust font size for smaller screens */
    }
}
  