/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", Helvetica, arial, sans-serif;
  box-sizing: border-box;
}

.history-s {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  width: 80%;
}

/* Left section with image */
.history-left-s {
  flex: 1 1 48%;
  padding: 10px;
  padding-left: 0;
  text-align: start;
}

.history-left-s img.smart-img {
  width: 100%;
  height: auto;
  max-width: 600px; 
  
}

/* Right section with text */
.history-right-s {
  flex: 1 1 48%;
  padding: 10px;
}

.history-right-s h1 {
  font-size: 32px;
  color: #98344E;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 20px;
}

.history-right-s p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  padding-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .history-s {
    flex-direction: column;
    padding: 10px;
  }

  .history-left-s, .history-right-s {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .history-right-s h1 {
    font-size: 28px;
    text-align: start;
  }

  .history-right-s p {
   
    text-align: start;
    padding-left: 0;
    font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  padding-top: 10px;
  }
}

@media (max-width: 600px) {
  .history-right-s h1 {
    font-size: 24px;
  }

  .history-right-s p {
    font-size: 18px;
  }
}
