/* Ensure no horizontal scroll */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}
.preschool {
  width: 100%;
  min-height: 80vh;
  color: #fff;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)),url("../../assests/kids_co-curricular_activities_images/P-3-main.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.about-preschool {
  text-align: flex-start;
  max-width: 800px;
}


.history {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px;
  width: 80%;
}


.history-left, .history-right {
  flex: 1 1 48%; /* Flex-grow, flex-shrink, flex-basis */
  box-sizing: border-box;
  padding: 10px;
}

.history-left {
  max-width: 50%;
}

.history-right {
  max-width: 50%;
}








.about-preschool h1 {
  text-align: flex-start;
  font-size: 30px;
  font-weight: 600px;
  letter-spacing: 3;
  margin-top: 450px;
  /* border-bottom: solid 1px #c00022; */
}

.preschool-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.preschool h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 30px;
  padding-left: 25px;
  color: #98344e;
}

.pre-right-section-choose h2 {
  display: block;
  font-size: 1.5em;
  margin-right: 92px;
  color: #98344e;
  font-style: normal;
}
.history-right h2{
  display: block;
  font-size: 1.5em;
  color: #98344e;
  font-style: normal;
  margin-left: 50px;
  text-align:start;
}
.history-right p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 50px;
}

.history-right li {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 90px;
}

.pre-choose-us{
  margin: 20px;
  display: flex;
  align-items:normal ;
  justify-content: space-between;
  width: 100%;

}

.pre-right-section-choose {
  flex-basis: 66%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 105px;

}

.pre-left-section-choose {
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 20px;
  
  }
  .pre-right-section-choose img{
    align-content: center;
    height: 90%;
    width: 100%;
    padding:25px;
   
    
  }

.pre-left-section-choose h1{
  padding-left: 10px;
  color: #98344e;
}
  .pre-left-section-choose p{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 300;
    padding-right: 30px;
    max-width: 600px;
    padding-left: 10px;
   
 ;
}

.pre-left-section-choose h2{
  padding-top: 10px;
}


.open-text-h2 h2 {
  display: block;
  font-size: 2.2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  margin-left: 30px;
  text-align: flex-start;
  color: #98344e;
}

.open-text-3 h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
  margin-left: 30px;
  color: #98344e;
}
.open-text-3 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 30px;
}

.pre-right-section-choose p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
 
}
.choose-elements h1 {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  /* text-align: center; */
  font-weight: 400;
  padding-top: 10px;
  color: #98344e;
}

.open-text-1 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 30px;
}
.open-text-20 h2{
  padding-top: 5px;
  
}


.images {
  margin: 4em;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.images img {
  height: 350px;
  width: 500px;
  padding: 15px;
  margin-top: 5px;
}

.open-text-h2 h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  /* font-weight: bold; */
  unicode-bidi: isolate;
 
  color: #98344e;
  font-style: normal;
}

.open-text-h2 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 30px;
}

/* ..........media query......... */
@media (max-width: 850px) {
  .contact {
    display: block;
  }
  .contact-call {
    padding: 15px;
  }
  .pre-choose-us{
   
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    align-items: flex-start;
    padding-top: 25px;
    padding-bottom: 25px;
  
  }

}
@media (max-width: 768px) {
  .history {
    flex-direction: column;
    align-items: flex-start;
  }

  .history-left, .history-right {
    max-width: 100%;
  }

  .history-right h2, .history-right p, .history-right li {
    text-align: start;
  }

  .history-right p {
    margin-top: 10px;
  }
}