/* Ensure no horizontal scroll */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }
.philosophy{
    width: 100%;
    min-height: 80vh;
    color: #fff;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("../../assests/Home/36.jpg");
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
}
    
    .our_philosophy{
        text-align:flex-start;
        max-width: 800px;
    }
    .our_philosophy h1{
        text-align:flex-start;
        font-size: 1.8em ;
        font-weight: 600px;
        letter-spacing: 3;
        margin-top: 450px;
    }

    .school_philosophy p{
        font-family: "Roboto", Helvetica, arial, sans-serif;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 300;
        margin-left: 30px;
        padding-bottom: 15px;
        }

        .school_philosophy h1{
            text-align:flex-start;
            font-size: 1.9em ;
            font-weight: 300px;
            letter-spacing: 3;
            margin-left: 30px;
            color: #98344E;
            padding-top: 15px;
            padding-bottom: 15px;
        }
 
