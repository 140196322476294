.privacy-sections{
    margin-top: 150px;
    margin-left: 30px;



}
.all-sections{
padding: 10px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
text-align: start;
max-width: 800px;
justify-content: flex-start;

}
.all-sections h1{
    font-weight: 400;
    font-size:  20px;
    color: #98344E;
    max-width: 450px;
    text-align: start;
}

.all-sections p{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    padding: 5px;
    font-weight: 300;
    max-width: 800px;
}
.all-sections li{
    font-family: 'Roboto', Helvetica, arial, sans-serif;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 5px;
    font-weight: 300;
    max-width: 800px;
    padding-left: 20px;
}