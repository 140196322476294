
.innovationSection{
    /* margin:  20px; */
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    width: 95%;
    padding-top: 60px;
   

}
.innovationSection img{
   width: 140%;
   /* border-radius: 7px; */
   height: 95%;
   /* margin-left: 40%; */
    
}

.section-left{
   flex-basis: 56%;
   position: relative;
   padding: 25px;
   padding-left: 8%;
 

}
.section-right{
    position: relative;
    padding: 40px;
    flex-basis: 55%;
    padding-right: 10%;
 }

 .section-right img{
 
   padding-left: 30px;
   padding-top: 100px;
   height: 100%;
 
}

 .innovationSection h1{
  font-size: 32px;
  color: #98344E;
  text-transform: none;
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  font-weight: 400;
  max-width: 400px;
  text-align: start;

 }

 .section-left p{
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  padding-top: 10px;
    
 }

  @media (max-width: 840px){
   .innovationSection{
     display: block;
     margin-left: 40;
     margin-right: 40px;
   }
   .section-left{
      flex-basis: 100%;
      margin: 5px ;
   }

   .section-right{
      flex-basis: 100%;
      margin-left: 20px;
    
   }
   .section-right img{
     margin: 0;
     padding: 0;
     align-items: center;
     max-width: 100%;
     
    
   }
 }


 @media (max-width: 540px){
   .innovationSection{
      display: block;
      margin-left: 20;
      margin-right: 20px;
   }
   .section-left{
      flex-basis: 100%;
      margin: 15px ;
   }

   .section-right{
      flex-basis: 100%;
      order:1 ;
   }
 }
