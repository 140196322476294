/* Ensure no horizontal scroll */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.jssschool {
  width: 100%;
  min-height: 70vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)),url("../../assests/jsc/208.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.about-jssschool {
  text-align: flex-start;
  max-width: 800px;
}



.juni-choose-us{
  margin: 20px;
  display: flex;
  align-items:normal ;
  justify-content: space-between;
  width: 100%;

}

.juni-left-section-choose {
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 25px;
  
  }
  .juni-right-section-choose {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 105px;
  
  }
  .juni-left-section-choose {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    align-items: flex-end;
    padding-top: 25px;
    padding-bottom: 25px;
    
    }
    .juni-right-section-choose img{
      align-content: center;
      height: 90%;
      width: 100%;
      padding:25px;
      padding-top: 75px;
     
    }
  
  .juni-left-section-choose h1{
    padding-left: 10px;
    color: #98344e;
  }
    .juni-left-section-choose p{
      font-family: 'Roboto', Helvetica, arial, sans-serif;
      letter-spacing: 1px;
      font-size: 18px;
      padding-top: 5px;
      font-weight: 300;
      padding-right: 30px;
      max-width: 600px;
     
     
   ;
  }
  
  .juni-left-section-choose h2{
    padding-top: 10px;
  }
  
.about-jssschool h1 {
  text-align: flex-start;
  font-size: 30px;
  font-weight: 600px;
  letter-spacing: 3;
  margin-top: 450px;
  /* margin-top: 10px; */
  /* border-bottom: solid 1px #c00022; */
}

.open-text-1 h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-top: 7px;
  color: #98344e;
}
.open-text-1 p{
    margin-top: 10px;
    margin-left: 30px;
    margin-bottom: 30px;
}

.open-text-3 h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  /* margin-top: 10px; */
  color: #98344e;
}
.open-text-3 p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
}

.juni-choose-section {
  margin: 10px;
  display: flex;
  align-items:normal ;
  justify-content: space-between;
  width: 100%;
}

.juni-why-section-choose{
  flex-basis: 67%;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 25px;

}
.juni-why-section-choose img{
  align-content: center;
  height: 95%;
  width: 100%;
  padding:25px;
 

}
.juni-why-choose-section {
  flex-basis: 67%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 55px;
  padding-top: 75px;
}
.juni-why-choose-section p{
  font-family: 'Roboto', Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
  padding-right: 30px;
  max-width: 600px;

}


.choose-left-section p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  max-width: 500px;
  margin-left: 30px;
  
}
.choose-left-section h2{
    margin-top: 15px;
    text-align: start;

}

.open-text-20 h2{
  margin-left: 25px;
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-top: 30px;
  color: #98344e;
  text-align: start;
}

.open-text-20 p{
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 25px;
  margin-right: 25px;
}

.why-choose-section h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-top: 30px;
  color: #98344e;
  text-align: flex-start;
}
.choose-elements h1 {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  text-align: flex-start;
  font-weight: 400;
  padding-top: 10px;
  color: #98344e;
}

.about-school-left{
    flex-basis: 40%;
    padding-left: 0px;

 }
 .about-school-left h1{
 color: #98344E;

}

 .why-choose-right-section img{
   width: 600px;
   height: 460px;
   padding-top: 30px;
   padding-bottom: 20px;
   padding-right: 40px;
 
 }

.why-choose-right-section{
    flex-basis: 35%;
    padding-left: 0px;
}

.images {
  margin: 4em;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.images img {
  height: 300px;
  width: 400px;
  padding: 15px;
  margin-top: 5px;
}
.whychoose-image {
  width: 60%;
  height: 50%;
  margin-left: 20px;
  flex-basis: 46%;
  align-items: center;
}
.open-text-2j h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 30px;
  padding-left: 25px;
  color: #98344e;
  text-align: flex-start;
}

.open-text-2j p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
  padding-left: 25px;
}

.images {
  margin: 40px auto;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.images p {
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 300;
}

li{
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  padding-top: 5px;
  font-weight: 300;
  padding-left: 30px;
 
  color: #98344E;
  
}
.learn-more p {
  font-family: "Roboto", Helvetica, arial, sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 300;
}
.program-images img {
  width: 100%;
  /* border-radius: 10px; */
  display: inline;
  height: 50%;
}
.program-images button{
  width: 100%;
}

.program-images {
  flex-basis: 30%;
  position: relative;
}

.caption {
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(rgba(8, 0, 58, 0.4), rgba(8, 0, 58, 0.4)), url("");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  cursor: pointer;
  font-size: small;
  opacity: 0;
  padding-top: 60%;
  transition: 0.4s;
  font-family: "Roboto", Helvetica, arial, sans-serif;
  font-weight: 300;
}

.caption p {
  font-size: 20px;
  font-weight: 400;
}
.caption img {
  width: 40px;
  margin-bottom: 10px;
}
.program-images:hover .caption {
  opacity: 1;
  padding-top: 0;
}
.btn-1 {
  background-color: #98344e;
  color: #fff;
  padding: 8px 40px;
  font-size: 15px;
  width: 90%;
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 2px;
  border: solid #fff 1px;
  font-weight: 400;
  /* line-height: 1.875rem; */
  min-width: 170px;
  align-content: center;
  transition: background-color 0.3s, color 0.3s;
}
.btn:hover {
  background-color: #98344e; /* Change background color on hover */
}

/* Make button responsive */
@media screen and (max-width: 840px) {
  .btn {
    font-size: 20px;
    font-weight: 400;
  }
}
@media screen and (max-width: 840px) {
  .juni-choose-us {
    flex-basis: 56%;
    display: block;

    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 840px) {
  .juni-choose-section{
    flex-basis: 67%;
    display: block;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
  }
  .juni-why-choose-section p {
    margin-left: 0;
    margin-right: 0;
    max-width: 600px;
   
  }
  .juni-why-choose-section h2{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;

  }

  .juni-why-section-choose{
    flex-basis: 60%;
    margin-right: 60px;

  }

}


@media (max-width: 840px) {
  .images {
    flex-direction: column;
  }
  .program-images {
    flex-basis: 60%;
    margin: 40px;
  }

  .juni-left-section-choose{
    order: 2;
  }

  
  .juni-left-section-choose img {
    margin-right: 50px;
    margin-left: 50px;
   
  }

  .open-text-2 h2{
    margin-left: 30px;
    margin-right: 30px;
    
  
  }

  .juni-why-choose-right-section img {
    margin-right: 50px;
    margin-left: 50px;
  }
}






/* ...............media query............ */

@media (max-width: 650px) {
  .contact {
    display: block;
  }
  .contact-call {
    padding: 15px;
  }
}
